import React from 'react'
import { Modal  } from 'antd'
import GetOrderDetail from '../GetOrderDetail/GetOrderDetail'

class GetOrderWindow extends React.Component{
    render(){
        return(
                <Modal
                title={this.props.title}
                visible={this.props.visible}
                onCancel={this.props.closeOrderWindow}
                footer={null}
                destroyOnClose={true}
                >
                <GetOrderDetail {...this.props} />
                </Modal>
                
        )
    }
}
export default GetOrderWindow