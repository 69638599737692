import 'whatwg-fetch'
import MD5 from 'md5'
import { Modal } from 'antd'
import createHistory from 'history/createHashHistory';
import {joinRequestParams} from '../utils/common'

// 操作历史
let history = createHistory();
// 需要退出登录的状态
let exitLogin = [1001,1002,1003,1005,2000,2001]
// 判断是否需要弹出弹窗
let isModal = true;
// 跨域调用m.tobosu.com需要用到的参数
const USER_ID = localStorage.getItem('userId') ? localStorage.getItem('userId') : '';
const TIME = new Date().getTime(); // 当前时间戳

class Request{
    // post 请求
    post = (url,formData = {}) => {
        Object.assign(formData,{
            device:'pc',
            token : localStorage.getItem('token') ? localStorage.getItem('token') : ''
        })
        return new Promise((resolve, reject) => {
            fetch(`/api/${url}`,{
                method:'POST',
                mode: "cors",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: joinRequestParams(formData)
            })
                .then(res => {
                    if (res.ok) {
                        return res.json();
                    } else if(res.status === 500) {
                        let errors = `${res.status}, ${res.statusText}`
                        throw errors
                    } else if (res.status === 404) {
                        let errors = `${res.status}, ${res.statusText}`
                        throw errors
                    }
                })
                .then(res => {
                    resolve(res);
                    tokenAbnormal(res);
                })
                .catch(err => {
                    reject(err);
                });
        })
    }
    // get 请求
    get = (url,formData = {}) => {
        Object.assign(formData,{
            device:'pc',
            token : localStorage.getItem('token') ? localStorage.getItem('token') : ''
        })
        return new Promise((resolve, reject) => {
            fetch(`/api/${url}?${joinRequestParams(formData)}`,{
                method:'GET',
                mode: "cors",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                }
            })
                .then(res => {
                    if (res.ok) {
                        return res.json();
                    } else if(res.status === 500) {
                        let errors = `${res.status}, ${res.statusText}`
                        throw errors
                    } else if (res.status === 404) {
                        let errors = `${res.status}, ${res.statusText}`
                        throw errors
                    }
                })
                .then(res => {
                    resolve(res);
                    tokenAbnormal(res);
                })
                .catch(err => {
                    reject(err);
                });
        })
    }
    // 跨域m站异步请求
    postM = (url,formData = {}) => {
        const DOMAIN = /^bip.tobosu.cn/.test(document.domain) ? 'https://m.tobosu.com/wxdistribute/' : 'http://m.dev.tobosu.com/wxdistribute/';
        Object.assign(formData,{
            id : USER_ID,
            time : TIME,
            token: MD5(TIME + 'tbs')
        })
        return new Promise((resolve, reject) => {
            fetch(DOMAIN+url,{
                method:'POST',
                mode: "cors",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: joinRequestParams(formData)
            })
                .then(res => {
                    if (res.ok) {
                        return res.json();
                    } else if(res.status === 500) {
                        let errors = `${res.status}, ${res.statusText}`
                        throw errors
                    } else if (res.status === 404) {
                        let errors = `${res.status}, ${res.statusText}`
                        throw errors
                    }
                })
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        })
    }
}

// token异常提示
function tokenAbnormal(res) {
    if(exitLogin.indexOf(res.code) > -1 && isModal){
        isModal = false;
        Modal.warning({
            title  : res.message,
            okText : '确定',
            onOk : () => {
                isModal = true;
                localStorage.removeItem('token');
                history.push('/login')
            }
        })
    }
}

export default new Request()






