import React from 'react'
import { Layout, Icon ,Menu,Badge,List,Avatar,Skeleton,Modal,Spin} from 'antd'
import {message} from "antd/lib/index";

import callInit from 'callInit'
import {Link} from "react-router-dom";
import {
    getNotifyNum, //消息总数
    getTopNotify, //消息弹窗
    getNotifyList, //消息列表
    getNotify, //消息详情
    setNotifyTotal // 头部未读消息
} from '../actions'
import { connect } from 'react-redux';
const { Header } = Layout
const SubMenu = Menu.SubMenu;

class Top extends React.Component{

    state={
        notifyNum:'',
        list:[], //消息弹窗列表数据
        count:[],//消息总数
        visible:false,
        title:'',
        windowLoading:false,
        detailMess:'',
        color:false // 头部消息列表信息置灰
    }


    Init =()=>{
        return async () => {
                this.props.headerNum==='' &&  await  this.props.getNotifyNum();

            await
                this.props.getTopNotify({}).then(res=>{
                    if(res.code===0){
                        this.setState({
                            list:res.data.list,
                            count:res.data
                        })

                    }
                })
        }
    }

    componentDidMount = () => {
        //请求消息
        this.Init()()
        Object.keys(this.props.userInfo).length === 0 && this.props.getUserInfo(res => {
            if(res.code === 0){
                // sessionStorage.setItem('userRank',res.data.rank);
                // sessionStorage.setItem('organRole',res.data.organ_role);
                // sessionStorage.setItem('areaRole',res.data.organ_area_ids);
                // localStorage.setItem('userId',res.data.id);

                // document.getElementById('agentID').value = res.data.agent_id;
                // document.getElementById('agentPWD').value = res.data.agent_pwd;
                // document.getElementById('employee_id').value = res.data.id;
                // 重新保存一次sessionStorage
                sessionStorage.setItem('agentID',res.data.agent_id);
                sessionStorage.setItem('agentPWD',res.data.agent_pwd);
                sessionStorage.setItem('employee_id',res.data.id);
                sessionStorage.setItem('is_maintain',res.data.is_maintain)
                document.getElementById('agentID').value = res.data.agent_id;
                document.getElementById('agentPWD').value = res.data.agent_pwd;
                document.getElementById('employee_id').value = res.data.id;
                document.getElementById('taskNum').value = res.data.task_id;
                // 加载电话条
                if (!!res.data.agent_id && !!res.data.agent_pwd) callInit()
                // callInit();

            }
        });
    };

    handleLogOut = (val) => {

        const _this = this;
        switch (val.key) {
            case 'logOut':
                message.loading('退出中',0);
                this.props.doLogout(function(res){
                    message.destroy();
                    if(res.code === 0){
                        message.success(res.message,1,function () {
                            localStorage.removeItem('token');
                            localStorage.removeItem('userId');
                            _this.props.history.push('/login');
                            window.location.reload();
                        })
                    }else{
                        message.error(res.message,1)
                    }
                })
                break;
        }

    }

    checkDetail=(id)=>{
        this.setState({
            windowLoading:true
        })
        //请求详情
        this.props.getNotify({id}).then(res=>{
            this.setState({
                windowLoading:false,
            })

            if(res.code === 0){
                this.setState({
                    detailMess:res.data.info
                })
            }else{
                this.setState({
                    data:[],
                    countInfo:{},
                })
            }

        })

        //详情弹窗
        this.setState({
            visible:true
        })

    }

    //关闭
    handleCancel=()=>{
        this.setState({
            visible:false
        })
    }

    test=()=>{
        this.handleCancel()
    }

    //去看看按钮
    getUrl=(type)=>{

        switch (type) {
            case 1:


                return (


                    <Link  to={{
                        pathname:'/business/us',
                    }}>
                        <a className="primary" onClick={()=>this.test()}>去看看</a>
                    </Link>
                )
            case 2:
                return (
                    <Link  to={{
                        pathname:'/audit/approve',
                    }}>
                        <a className="primary" onClick={()=>this.test()}>去看看</a>
                    </Link>

                )
            case 3:

                return (
                    <Link  to={{
                        pathname:'/audit/my',
                    }}>
                        <a className="primary" onClick={()=>this.test()}>去看看</a>
                    </Link>
                )
            case 4:
                //抄送我的页签
                return (
                    <Link  to={{
                        pathname:'/audit/approve',
                        handleCancel:this.handleCancel(),
                        value:'4'
                    }}>
                        <a className="primary" onClick={()=>this.test()}>去看看</a>
                    </Link>
                )
            case 5:
                return (


                    <Link  to={{
                        pathname:'/business/manage',
                    }}>
                        <a className="primary" onClick={()=>this.test()}>去看看</a>
                    </Link>

                )
            case 6:
                return (

                    <Link  to={{
                        pathname:'/business/query',
                    }}>
                        <a className="primary" onClick={()=>this.test()}>去看看</a>
                    </Link>



                )
            case 7:
                return (
                    <Link  to={{
                        pathname:'/business/blacklist',
                    }}>
                        <a className="primary" onClick={()=>this.test()}>去看看</a>
                    </Link>
                )
        }
    }
    //审批状态
    status=(audit)=>{
        switch (audit) {
            case '0':
                return '待审批';
            case '1':
                return '已通过';
            case '2':
                return '未通过';
            case '3':
                return '已撤销';
            case '4':
                return '已通过(抄送)';
        }
    }


    //详情类型展示
    detailShow=(type,data)=>{

        switch (type) {
            case 1:
                return (
                    <div className='tips-detail'>
                        <p className='company_name'>{data.co_name}</p>
                        <p><span>客户ID:</span>{data.co_id}</p>
                        <p><span>公司ID:</span>{data.com_id}</p>
                        <p><span>所在城市:</span>{data.city_name}</p>
                        <p><span>审批状态:</span>
                            {this.status(data.audit_state)}
                        </p>
                        <div className='btn-header'>
                            <a onClick={()=>this.handleCancel()}>我知道了</a>
                            {
                                this.getUrl(data.url_type)
                            }
                        </div>
                    </div>
                )
            case 2:
                return (
                    <div className='tips-detail'>
                        <p className='company_name'>{data.co_name}</p>
                        <p><span>客户ID:</span>{data.co_id}</p>
                        <p><span>公司ID:</span>{data.com_id}</p>
                        <p><span>所在城市:</span>{data.city_name}</p>
                        <p><span>预警原因:</span>{data.warn_desc}</p>
                        <div className='btn-header'>
                            <a onClick={()=>this.handleCancel()}>我知道了</a>
                            {
                                this.getUrl(data.url_type)
                            }
                        </div>
                    </div>
                )
            case 3:
                return (
                    <div className='tips-detail'>
                        <p className='company_name'>{data.co_name}</p>
                        <p><span>客户ID:</span>{data.co_id}</p>
                        <p><span>公司ID:</span>{data.com_id}</p>
                        <p><span>所在城市:</span>{data.city_name}</p>
                        <p><span>流失原因:</span>{data.lose_desc}</p>
                        <div className='btn-header'>
                            <a onClick={()=>this.handleCancel()}>我知道了</a>
                            {
                                this.getUrl(data.url_type)
                            }
                        </div>
                    </div>
                )
            case 4:
                return (
                    <div className='tips-detail'>
                        <p className='company_name'>{data.co_name}</p>
                        <p><span>客户ID:</span>{data.co_id}</p>
                        <p><span>公司ID:</span>{data.com_id}</p>
                        <p><span>所在城市:</span>{data.city_name}</p>
                        <p><span>会员级别:</span>{data.member_type}</p>
                        <p><span>剩余订单:</span>{data.order}</p>
                        <p><span>余额(元):</span>{data.balance}</p>
                        <div className='btn-header'>
                            <a onClick={()=>this.handleCancel()}>我知道了</a>
                            {
                                this.getUrl(data.url_type)
                            }
                        </div>
                    </div>
                )
            case 5:
                return (
                    <div className='tips-detail'>
                        <p className='company_name'>{data.co_name}</p>
                        <p><span>客户ID:</span>{data.co_id}</p>
                        <p><span>公司ID:</span>{data.com_id}</p>
                        <p><span>所在城市:</span>{data.city_name}</p>
                        <p><span>转移前归属于:</span>{data.before_name}</p>
                        <p><span>转移后归属于:</span>{data.order}</p>
                        <p><span>操作人:</span>{data.operater_name}</p>
                        <div className='btn-header'>
                            <a onClick={()=>this.handleCancel()}>我知道了</a>
                            {
                                this.getUrl(data.url_type)
                            }
                        </div>
                    </div>
                )
            case 6:
                return (
                    <div className='tips-detail'>
                        <p className='company_name'>{data.co_name}</p>
                        <p><span>客户ID:</span>{data.co_id}</p>
                        <p><span>公司ID:</span>{data.com_id}</p>
                        <p><span>所在城市:</span>{data.city_name}</p>
                        <p><span>指派人:</span>{data.invite_name}</p>
                        <p><span>指派说明:</span>{data.invite_desc}</p>
                        <div className='btn-header'>
                            <a onClick={()=>this.handleCancel()}>我知道了</a>
                            {
                                this.getUrl(data.url_type)
                            }
                        </div>
                    </div>
                )
            case 7:
                return (
                    <div className='tips-detail'>
                        <p className='company_name'>{data.co_name}</p>
                        <p><span>客户ID:</span>{data.co_id}</p>
                        <p><span>公司ID:</span>{data.com_id}</p>
                        <p><span>所在城市:</span>{data.city_name}</p>
                        <p><span>操作人:</span>{data.operater_name}</p>
                        <p><span>拉黑原因:</span>{data.black_desc}</p>
                        <div className='btn-header'>
                            <a onClick={()=>this.handleCancel()}>我知道了</a>
                            {
                                this.getUrl(data.url_type)
                            }
                        </div>
                    </div>
                )
        }
    }

    //点击头部消息 a变色
    colorChange=(index)=>{
        //此列表都是未读消息
        let num=this.props.headerNum
        num !==0 && this.props.setNotifyTotal(num-1)

        //更改是否已读状态
        this.state.list[index].is_read = 1 ;
        //color 点击更改颜色 true：已点击 false 未点击
        this.state.list[index].color = true;

        //重新赋值
        this.setState({
            list:this.state.list,
        })
    }


    render(){
        const userInfo = this.props.userInfo;
        return(
            <Header className="system-header">
                <Icon
                    className="system-trigger"
                    type={this.props.collapsed ? 'menu-unfold' : 'menu-fold'}
                    onClick={this.props.toggle}
                />
                <Menu mode="horizontal" className="avatar-cons" onClick={this.handleLogOut} selectedKeys={['']}>
                    <SubMenu
                        className='subtest'
                        title={
                            <div className="avatar">
                                <Link
                                    className="avatarList"
                                    to={{
                                    pathname:'/public/messList',
                                }}>
                                    <Icon type="bell" />
                                    <div className='messtip'>
                                        <Badge count={this.props.headerNum}>

                                            消息
                                        </Badge>
                                    </div>
                                </Link>


                            </div>
                        }
                    >

                        {

                            this.state.list.length>0 ?
                            this.state.list.map((item,index)=>{
                                return (
                                    <Menu.Item
                                        className='messTip-Height new-system-header'
                                        key={index}
                                    >
                                        <div className='messtip-content' key={item.id} onClick={()=>this.checkDetail(item.id)}>
                                            <div >
                                                <span className='messtip-title'>{item.type_name}</span>
                                                <span className='messtip-time'>{item.create_time}</span>

                                            </div>
                                            <a onClick={()=>this.colorChange(index)}  className= {  item.color ? 'messtip-text visitedColor' : 'messtip-text'  }       dangerouslySetInnerHTML={{ __html: item.content }}  />
                                        </div>
                                    </Menu.Item>
                                    )

                            }) :
                                <div>暂无消息通知</div>
                        }

                        {
                            this.state.list.length>0 &&
                            <Menu.Item>
                            <Link  to={{
                                pathname:'/public/messList',
                            }}>
                                <div className='check-btn-warp'>
                                    <a className='check-btn' >
                                        查看全部消息({this.state.count.all_count}条)
                                    </a>
                                </div>
                            </Link>
                            </Menu.Item>
                        }

                    </SubMenu>


                    <SubMenu
                        title={
                            <div className="avatar">

                                <div className="img">
                                    {userInfo.real_name && userInfo.real_name.substr(0,1)}
                                </div>
                                <p>{userInfo.real_name}</p>
                            </div>
                        }
                    >
                        <Menu.Item key="logOut" selectable={false}>退出</Menu.Item>
                    </SubMenu>
                </Menu>

                <Modal
                    title={this.state.detailMess.type_name}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={null}
                    className='mess-tips-header'
                >
                    {
                        this.state.windowLoading ? <Spin/>:

                        this.detailShow(this.state.detailMess.type,this.state.detailMess)}
                </Modal>
            </Header>
        )
    }
}

//
function mapStateToProps(state){
    return{
        headerNum:state.getNotifyNum
    }
}
export default connect(mapStateToProps,{
    getNotifyNum,
    getTopNotify,
    getNotifyList,
    getNotify, //消息详情
    setNotifyTotal //存储header未读消息
})(Top);