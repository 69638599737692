import React from 'react'
import {Route,Switch} from 'react-router-dom'
import { Layout } from 'antd';
// 按需加载路由
import asyncComponent from '../router/asyncComponent'


// 公共组件路由
const PublicRouter = asyncComponent(() => import('../router/publicRouter'))
// 首页路由
const IndexRouter = asyncComponent(() => import('../router/indexRouter'))
// 客户管理路由
const BusinessRouter = asyncComponent(() => import('../router/businessRouter'))
// 数据统计路由
const DataCountRouter = asyncComponent(() => import('../router/dataCountRouter'))
// 商务审批路由
const AuditRouter = asyncComponent(() => import('../router/auditRouter'))
// 会员管理路由
const MemberRouter = asyncComponent(() => import('../router/memberRouter'))
// 维护中心路由
const AssertRouter = asyncComponent(() => import('../router/assertRouter'))
// 工具路由
const ToolRouter = asyncComponent(() => import('../router/toolRouter'))

const { Content } = Layout;
// //消息列表
// import MessList from './components/messlist'
//
// import Index from '../pages/index'
//
// //客户管理
// import UsCustomer from '../pages/business/usCustomer' //全部客户
// // import AllCustomers from '../pages/customer/newallCustomer'  这个路由暂时不删。等待查看
// import AllCustomer from '../pages/customer/allCustomer'
// import Manage from '../pages/business/manage'
// import Assign from '../pages/business/assign'
// import BlackList from '../pages/business/blacklist'
// import CommonCustomer from '../pages/business/businessCustomer'
// import query from '../pages/business/sunQuery'
// import RobbRule from '../pages/business/robbRule'
// import Organize from '../pages/business/organize'
// import Histroy from '../pages/business/history'
//
//
// import VisitCustomer from '../pages/customer/visitCustomer'
// import NewCustomer from '../pages/customer/newCustomer'
// import FollowingCustomer from '../pages/customer/followingCustomer'
// import FinishCustomer from '../pages/customer/finishCustomer'
// import FllowingRecord from '../pages/record/fllowingRecord'
// import CallRecord from '../pages/record/callRecord'
// import UserCount from '../pages/count/userCount'
// //数据统计
// import followCount from '../pages/dataCount/followCount'
// import Quality from '../pages/dataCount/quality'
// import Invitation from '../pages/dataCount/invitation'
// import Track from '../pages/dataCount/track'
// import FollowDayPaper from '../pages/dataCount/followDayPaper'
// import List from '../pages/dataCount/list'
// import Queue from '../pages/dataCount/queue'
// // 商务审批
// import MyAudit from '../pages/audit/my'
// import Approve from '../pages/audit/approve'
// // 会员管理
// import CompanyMess from '../pages/member/companymess'
// // 维护中心
// import WaitOrder from '../pages/assert/waitOrder'
// import AlreadyOrder from '../pages/assert/alreadyOrder'
// import CancelManage from '../pages/assert/cancelManage'
// import AssignMent from '../pages/assert/assignMent'
// import AssignMented from '../pages/assert/assignMented'
// import FeedBack from '../pages/assert/feedback'
// import FollowUp from '../pages/assert/followup'
// import PayNotice from '../pages/assert/paynotice'
// import PayManage from '../pages/assert/paymanage'
// import WarnTool from '../pages/assert/warntool'
// import PayBackList from '../pages/assert/paybacklist'
// import PersonalTool from '../pages/assert/personaltool'
// // 工具
// import OrderCompute from '../pages/tool/orderCompute'

// class Contents extends React.Component{
//     render(){
//         return(
//             <Content className="system-content">
//                 <Switch>
//                     {/*消息列表*/}
//                     <Route path='/messList' component={MessList} />
//                     {/*<Route path='/customer/us' component={AllCustomers} />*/}
//                     <Route path='/business/us' component={UsCustomer} />
//                     <Route path='/business/organize' component={Organize} />
//                     <Route path='/business/common' component={CommonCustomer} />
//                     <Route path='/business/query' component={query} />
//                     <Route path='/business/manage' component={Manage} />
//                     <Route path='/business/assign' component={Assign} />
//                     <Route path='/business/blacklist' component={BlackList} />
//                     <Route path='/business/robbing' component={RobbRule} />
//                     <Route path='/business/history' component={Histroy} />
//
//                     <Route path='/customer/all' component={AllCustomer} />
//                     <Route path='/customer/visit' component={VisitCustomer} />
//                     <Route path='/customer/new' component={NewCustomer} />
//                     <Route path='/customer/fllowing' component={FollowingCustomer} />
//                     <Route path='/customer/finish' component={FinishCustomer} />
//                     <Route path='/record/fllowing' component={FllowingRecord} />
//                     <Route path='/record/call' component={CallRecord} />
//                     {/*数据统计*/}
//                     <Route path='/dataCount/queue' component={Queue} />
//                     <Route path='/dataCount/followCount' component={followCount}/>
//                     {/*<Route path='/business/datacount' component={DataCount}/>*/}
//                     <Route path='/dataCount/quality' component={Quality}/>
//                     <Route path={'/dataCount/invitation'} component={Invitation}/>
//                     <Route path={'/dataCount/track'} component={Track} />
//                     <Route path={'/dataCount/followDayPaper'} component={FollowDayPaper} />
//                     <Route path={'/dataCount/list'} component={List} />
//                     {/*商务审批*/}
//                     <Route path='/audit/my' component={MyAudit}></Route>
//                     <Route path='/audit/approve' component={Approve}></Route>
//                     {/*会员中心*/}
//                     <Route path='/member/info' component={CompanyMess}></Route>
//                     {/*维护中心*/}
//                     <Route path='/assert/wait' component={WaitOrder}></Route>
//                     <Route path='/assert/already' component={AlreadyOrder}></Route>
//                     <Route path='/assert/cancel' component={CancelManage}></Route>
//                     <Route path='/assert/assignment' component={AssignMent}></Route>
//                     <Route path='/assert/assignmented' component={AssignMented}></Route>
//                     <Route path='/assert/feedback' component={FeedBack}></Route>
//                     <Route path='/assert/followup' component={FollowUp}></Route>
//                     <Route path='/assert/personaltool' component={PersonalTool}></Route>
//                     <Route path='/assert/paynotice' component={PayNotice}></Route>
//                     <Route path='/assert/paymanage' component={PayManage}></Route>
//                     <Route path='/assert/warntool' component={WarnTool}></Route>
//                     <Route path='/assert/paybacklist' component={PayBackList}></Route>
//                     <Route path='/count/user' component={UserCount} />
//                     {/*工具*/}
//                     <Route path='/tool/compute' component={OrderCompute} />
//                     {/*首页*/}
//                     <Route path='/' component={Index} />
//                     {/*<Route path='/index' component={Index} />*/}
//                     <Route path='/home' component={Index} />
//                 </Switch>
//             </Content>
//         )
//     }
// }

class Contents extends React.Component {
    render() {
        return (
            <Content className="system-content">
                <Switch>
                    {/*公共组件*/}
                    <Route path='/public' component={PublicRouter} />
                    {/*客户管理*/}
                    <Route path='/business' component={BusinessRouter} />
                    {/*数据统计*/}
                    <Route path='/dataCount' component={DataCountRouter} />
                    {/*商务审批*/}
                    <Route path='/audit' component={AuditRouter} />
                    {/*会员管理*/}
                    <Route path='/member' component={MemberRouter} />
                    {/*维护中心*/}
                    <Route path='/assert' component={AssertRouter} />
                    {/*工具*/}
                    <Route path='/tool' component={ToolRouter} />
                    {/*首页*/}
                    <Route path='/' component={IndexRouter} />
                </Switch>
            </Content>
        )
    }
}
export default Contents