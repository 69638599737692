// 公共资源actions
import {getPathname, isFunction} from "../utils/common"
import Request from '../fetch'
const {post,get} = Request

// 左侧菜单激活，一般用于首次加载 link : this.props.location.pathname
export function changeMenuActive(link) {
    // 获取链接匹配相对应的展开菜单
    const pathname = getPathname(link);
    // 判断传过来的pathname的数组是否大于0,渲染当前选中的菜单
    let selectkey = '',
        openKeys = [],
        selectedKeys = [];
    // 提取组合成openKeys && selectedKeys
    pathname.map((item,index,arr) => {
        selectkey += `_${item}`;
        if(index < arr.length-1){
            openKeys.push(selectkey.replace(/^_/,''));
        }
        if(index === arr.length-1){
            selectedKeys.push(selectkey.replace(/^_/,''))
        }
        return ''
    })
    return dispath => {
        dispath({
            type : 'MENU_ACTIVE',
            data : {
                openKeys,
                selectedKeys
            }
        })
    }
}

// 改变展开的SubMenu
export function changeSubMenu(keys) {
    return dispath => {
        dispath({
            type : 'SUB_MENU',
            subMenu : keys
        })
    }
}

// 改变选中的MenuItem
export function changeMenuItem(key) {
    return dispath => {
        dispath({
            type : 'MENU_ITEM',
            menuItem : key
        })
    }
}




// 获取负责人列表
export function getRespondList() {
    return dispath => {
        get('sale/getrespondlist').then(res => {
            if(res.code === 0){
                dispath({
                    type : 'respondList',
                    data : res.data
                })
            }
        })
    }
}

// 获取权限城市列表
export function getRightCity(obj){
    return dispath => {
        post('user/getusercitylist',obj).then(res => {
            if(res.code === 0){
                dispath({
                    type : 'rightCity',
                    data : res.data
                })
            }
        })
    }
}

// 获取用户开放城市 跟上面的获取权限城市的接口一样，只是改成了异步接口
export function getUserCity(obj) {
    return async dispath => {
        let res = await get('user/getusercitylist',obj)
        return res
    }
}

// 获取用户开放省份
export function getUserProvince(obj) {
    return async dispath => {
        let res = await get('user/getUserProvince',obj)
        return res
    }
}

// 2018-11-9 获取维护中心的权限列表
export function getMaintainCityList(){
    return async dispath => {
        let res = await post('user/getmaintaincitylist')
        if(res.code === 0){
            dispath({
                type : 'MAINTAUNT_CITY',
                data : res.data
            })
        }
        return res;
    }
}

// 获取负责区域
export function getAreaList(){
    return dispath => {
        post('public/getarealist').then(res => {
            if(res.code === 0){
                dispath({
                    type : 'areaList',
                    data : res.data
                })
            }
        })
    }
}
// 获取用户开发大区
export function getUserAreaList(){
    return dispath => {
        post('user/getuserarealist').then(res => {
            if(res.code === 0){
                dispath({
                    type : 'userAreaList',
                    data : res.data
                })
            }
        })
    }
}
// 获取省份接口
export function getProvinceList(call) {
    return dispath => {
        post('public/getprovincelist').then(res => {
            isFunction(call) && call(res);
        })
    }
}

// 获取城市接口
export function getCityList(obj,call) {
    return dispath => {
        post('public/getcitylist',obj).then(res => {
            isFunction(call) && call(res);
        })
    }
}

// 获取区县接口
export function getCountyList(obj,call) {
    return dispath => {
        post('public/getcountylist',obj).then(res => {
            isFunction(call) && call(res);
        })
    }
}
// 2018/11/2 改为异步函数
export function asyncCountyList(obj) {
    return async dispath => {
        let res = await post('public/getcountylist',obj);
        return res;
    }
}

// 批量导出
export function exportComList(obj,call){
    return dispath => {
        get('sale/exportcomlist',obj).then(res => {
            isFunction(call) && call(res)
        })
    }
}

// 文件上传
export function importFile(obj,call){
    return dispath => {
        post('sale/importcom',obj).then(res => {
            isFunction(call) && call(res)
        })
    }
}


// 获取员工列表
// employee/getnamelist 先用负责人列表的接口
export function getNameList(){
    return dispath => {
        post('sale/getrespondlist').then(res =>{
            res.code === 0 &&
            dispath({
                type : 'nameList',
                data : res.data
            })
        })
    }
}

export function importImage(obj) {
    const newObj = Object.assign(obj,{s_code : 'cancel_order_prove'})
    return async dispath => {
        let res = await post('http://www.dev.tobosu.com/cloud/upload/upload_image',newObj)
        return res
    }
}

//header头部消息
export function getNotifyNum(obj) {

    return async dispath => {
        let res = await get('notify/getNotifyNum',obj);
        res.code===0 &&
            dispath({
                type:'HEADER_NUM',
                data:res.data.num
            })
        return res;
    }
}
//存储total
export function setNotifyTotal(num) {
    return dispath => {
        dispath({
            type : 'NOTIFY_TOTAL',
            data : num
        })
    }
}



//消息弹窗
export function getTopNotify(obj) {
    return async dispath => {
        let res = await get('notify/getTopNotify',obj);
        return res;
    }
}

//消息列表
export function getNotifyList(obj) {
    return async dispath => {
        let res = await get('notify/getNotifyList',obj);
        res.code === 0 &&
        dispath({
            type : 'NOTIFY_LIST',
            data : res.data
        })
        return res;
    }



    // return async dispath => {
    //     let res = await get('notify/getNotifyList',obj);
    //     return res;
    // }
}
//获取消息详情
export function getNotify(obj) {
    return async dispath => {
        let res = await get('notify/getNotify',obj);
        return res;
    }
}

//标记消息为已读
export function setMarkRead(obj) {
    return async dispath => {
        let res = await post('notify/markRead',obj);
        return res;
    }
}