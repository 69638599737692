import { isFunction } from '../utils/common'
import Request from '../fetch'
const {post,get} = Request



//转移操作负责人信息
export function getTransUser(obj) {
    return async dispath=>{
        let res = await post('organ/getTransUser',obj);
        return res;
    }

}

//邀约列表
export function getInviteStaff(obj) {
    return dispath =>{
        get('organ/getInviteStaff',obj).then(res=>{
            if(res.code === 0){
                dispath({
                    type:'InviteStaff',
                    data:res.data.list ? res.data.list : []
                })
            }
        })
    }
}



export function getQueryLists() {

    // return async  dispath=>{
    //     // let res = await  post('call_group/changeStateCallGroup',obj);
    //     return res;
    // }

}
//获取所有人员信息
export function getAllStaff(obj) {
    return dispath =>{
        get('organ/getAllStaff',obj).then(res=>{
            if(res.code === 0){
                dispath({
                    type:'AllStaff',
                    data:res.data.list ? res.data.list : []
                })
            }
        })
    }
}



//转移客户操作
export function getTransOrder(obj) {
    return async dispath=>{
        let res = await post('grab/transOrder',obj);
        return res;
    }
}

//获取综合查询列表
export function getCompositeList(obj) {
    return async dispath=>{
        let res = await get('sale/getCompositeList',obj);
        return res;
    }
}



//抢单规则操作记录列表
export function getlogList(obj,call){
    return dispath=>{
        get('grab/getGrabConfLogList',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}
//抢单配置信息
export function getgrabInfo(obj,call){
    return dispath=>{
        get('grab/getGrabConfInfo',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}
//编辑抢单配置信息
export function getsaveConf(obj,call){
    return dispath=>{
        post('grab/saveGrabConf',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}
//公共客户列表 
export function getpublicomlist(obj,call){
    return dispath=>{
        get('sale/getpubliccomlist',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}
//领取客户
export function getOrder(obj,call){
    return dispath=>{
        post('grab/grabOrder',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}

//获取客户流转数据
export function getOrderTransfer(obj){
    return dispath =>{
        post('grab/getOrderTransfer',obj).then(res=>{
            if(res.code === 0){
                dispath({
                    type:'transfer',
                    data:res.data.list ? res.data.list : []
                })
            }
        })
    }
}


//放弃客户
export function  cancelOrder(obj,call){
    console.log(obj)
    return dispath=>{
        post('grab/cancelOrder',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}

//延期
export function delayOrders(obj,call){
    return dispath=>{
        post('grab/delayOrder',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}

//查看范围(成员信息)
export function getMembers(obj){
    return dispath =>{
        get('organ/getmembers',obj).then(res=>{
            if(res.code === 0){
                dispath({
                    type:'MEMBERS',
                    data:res.data.list
                })
            }
        })
    }
}

//查看范围(区域信息)
export function getAreas(){
    return dispath=>{
        get('organ/getareas').then(res=>{
            if(res.code ===0 ){
                dispath({
                    type:'AREAS',
                    data:res.data.list
                })
            }
        })
    }
}


// 组织架构
// 获取组织架构信息
export function getOrganList(call) {
    return dispath => {
        post('organ/organList').then(res=>{
            if(res.code === 0){
                dispath({
                    type : 'ORGAN_LIST',
                    data : res.data.organ_list
                })
            }else if(res.code === 201){
                dispath({
                    type : 'ORGAN_EMPTY'
                })
            }
            isFunction(call) && call(res)
        })
    }
}

//单个组织信息
export function getOrganByArea(obj,call){
    return dispath=>{
        get('organ/organByArea',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}

//获取负责人
export function getJobList(call){
    return dispath=>{
        get('organ/getonjoblist').then(res=>{
            if(res.code === 0){
                dispath({
                    type : 'JOB_LIST',
                    data : res.data.list
                })
            }
            isFunction(call) && call(res)
        })
    }
}

//添加角色
export function getAddRole(obj,call){
    return dispath=>{
        post('organ/addrole',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}
//删除角色
export function delrole(obj,call){
    return dispath=>{
        post('organ/delrole',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}

//数据统计
export function getSaleList(obj,call){
    return dispath=>{
        get('stats/getsalelist',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}

//数据明细
export function getDetail(obj,call){
    return dispath=>{
        get('stats/getsaledetaillist',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}

//邀约追踪
export function inviteResStats(obj) {
    return async dispath => {
        let res = await get('stats/getInviteResStats',obj);
        return res
    }
}

//数据质量分析
export function getQuality(obj,call){
    return dispath=>{
        get('stats/getclientqualitystats',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}



//获取分组列表
export function getCallGroupList(obj) {
    // return dispath=>{
    //     post('call_group/getCallGroupList',obj).then(res=>{
    //         isFunction(call) && call(res)
    //     })
    // }
    return async dispath=>{
        let res = await post('call_group/getCallGroupList',obj);
        return res
    }
}
//添加分组信息
export function saveCallGroup(obj) {
    return async dispath=>{
        let res = await post('call_group/saveCallGroup',obj);
        return res
    }
}

//修改
export  function editoneGroup(obj) {
    return async dispath=>{
        let res = await post('call_group/editonegroup',obj);
        return res
    }
}
//删除分组信息
export  function RemoveCallGroup(obj) {
    return async  dispath=>{
        let res = await  post('call_group/changeStateCallGroup',obj);
        return res;
    }
}
//编辑成员
export function getAllOrganMember(obj) {
    return async dispath=>{
        let res = await post('call_group/getAllOrganMember',obj);
        return res;
    }
}

//编辑呼叫范围
export  function getAreaAndCity(obj) {
    return async dispath=>{
        let res = await post('call_group/getAreaAndCity',obj);
        return res;
    }
}

//小号绑定
export function bindAxb(obj) {
    return async dispth=>{
        let res =await post('axb/bindAxb',obj);
        return res;
    }
}


//小号数量
export  function phoneNumber(obj) {
    return async dispath=>{
        let res =await post('axb/phoneNumber',obj);
        return res;
    }
}

//小号解绑
export function nobindAxb(obj) {
    return async dispath=>{
        let res = await post('axb/nobindAxb',obj);
        return res;
    }
}

//首页统计数据
export function getStatsIndex(obj) {
    return async dispath=>{
        let res = await get('stats/getStatsIndex',obj);
        return res;
    }
}

//个人/区域排行榜
export function getBillboard(obj) {
    return async dispath=>{
        let res = await get('stats/getBillboard',obj);
        return res;
    }
}

//首页获取全国榜单
export function getWholeBillboard(obj) {
    return async dispath=>{
        let res = await get('stats/getWholeBillboard',obj);
        return res;
    }
}

//获取客户跟进情况/呼叫客户数统计/呼叫次数统计
export function getComStats(obj) {
    return async dispath=>{
        let res = await get('stats/getComStats',obj);
        return res;
    }
}

// 查看语音文件日志记录
export function recordClickVoice(obj) {
    return async dispath=>{
        let res = await post('call/recordClickVoice',obj);
        return res;
    }
}

//查询续费客户管理
export function getRenewList(obj) {
    return async dispath=>{
        let res = await get('maintain/getRenewList',obj);
        return res;
    }
}

//获取维护组
export function getMaintainList(obj) {
    return async dispath=>{
        let res = await get('maintain/getMaintainList',obj);
        return res;
    }
}

//获取客户分配列表
export function getRenewDivideList(obj) {
    return async dispath=>{
        let res = await get('maintain/getRenewDivideList',obj);
        return res;
    }
}
//分配客户
export  function getdistributCom(obj) {
    return async dispath=>{
        let res = await post('maintain/distributCom',obj);
        return res;
    }
}

//获取黑名单列表
export  function getComBlackList(obj) {
    return async dispath=>{
        let res = await get('maintain/getComBlackList',obj);
        return res;
    }
}


//移入黑名单
export  function addComBlack(obj) {
    return async dispath=>{
        let res = await post('maintain/addComBlack',obj);
        return res;
    }
}
//移出黑名单
export  function removeComBlack(obj) {
    return async dispath=>{
        let res = await post('maintain/removeComBlack',obj);
        return res;
    }
}

//会员续费
export  function getRenewConf(obj) {
    return async dispath=>{
        let res = await get('config/getRenewConf',obj);
        return res;
    }
}

// saveRenewConf
//修改会员配置
export  function SetsaveRenewConf(obj) {
    return async dispath=>{
        let res = await post('config/saveRenewConf',obj);
        return res;
    }
}


