import React , {Component} from 'react';
import {HashRouter,BrowserRouter} from 'react-router-dom';
import Router from './router/routerMap';

import createHistory from 'history/createHashHistory';
const history = createHistory();

class App extends Component{
    componentWillMount(){
    }
    render(){
        return(
            <HashRouter>
                <Router history={history} />
            </HashRouter>
        )
    }
}

export default App;