// 负责人列表
export function getRespondList(state=[],action){
    switch (action.type){
        case 'respondList' :
            return action.data
            break;
        default :
            return state
    }
}
// 权限城市列表
export function getRightCity(state=[],action){
    switch (action.type){
        case 'rightCity' :
            return action.data
            break;
        default :
            return state
    }
}

// 获取负责区域
export function getAreaList(state=[],action){
    switch (action.type){
        case 'areaList' :
            return action.data
            break;
        default :
            return state
    }
}

// 获取客户列表
export function getComList(state=[],action){
    switch (action.type){
        case 'comList' :
            return action.data
            break;
        default :
            return state
    }
}

// 获取客户详细信息
export function getComInfo(state={},action){
    switch (action.type){
        case 'comInfo' :
            return action.data
            break;
        case 'delComInfoRex':
            return state
        default :
            return state
    }
}


// 获取客户跟进记录
export function getComFollow(state=[],action){
    switch (action.type){
        case 'comFollow' :
            return action.data
            break;
        default :
            return state
    }
}

//获取客户操作纪录
export function getComOperateList(state=[],action) {
    switch (action.type){
        case 'operate' :
            return action.data
            break;
        default :
            return state
    }
}







