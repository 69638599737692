import React from 'react'
import { Link } from 'react-router-dom'
import { Layout, Menu, Icon } from 'antd'

import { allMenu } from "../utils/menu";

import { getPathname,isArray } from '../utils/common'
import {
    getMsgCount,
    changeMenuActive,       // 菜单激活
    changeSubMenu,          // 展开或者收起SubMenu
    changeMenuItem,         // 选中MenuItem
} from '../actions'
import { connect } from 'react-redux';
const { Sider } = Layout
const { SubMenu } = Menu

class Left extends React.Component{
    state={

        newnotic:'',
    };

    componentDidMount(){
        // 获取菜单激活状态
        this.props.changeMenuActive(this.props.location.pathname)
        //请求有多少个公司（通知）
        this.MsgCount();
        setInterval(this.MsgCount,300000);
    }
    MsgCount=()=>{
        this.props.getMsgCount({},res=>{
            if(res.code === 0 ){
                this.setState({
                    newnotic:res.data.count
                })
            }else{
                this.setState({
                    newnotic:''
                })
            }
        })
    };
    // 操作权限
    handleRank = () =>{
        const userRank = sessionStorage.getItem('userRank');
        return !userRank || userRank === "" || userRank === 'guest' || userRank === 'staff' || userRank === 'director' ? false : true
    };
    //负责人权限（组织架构中设置）
    organizRank =()=>{
        const organRole = sessionStorage.getItem('organRole');

        return organRole === 'leader' ? true : false
    };

    //抢单规则设置权限（组织架构中有全部区域的）
    areaRole=()=>{
        const areaRole = sessionStorage.getItem('areaRole');

        return areaRole === '-1' ? true:false
    };



    //是否邀约组
    maintain=()=>{
        const is_maintain = sessionStorage.getItem('is_maintain');
        return typeof(is_maintain)==='undefined' ? '0' : is_maintain
    }

    //组织架构中有全部区域的  -1时为全部区域  '' 不在组织架构中 -98 邀约组
    areaRoles=()=>{
        const areaRole = sessionStorage.getItem('areaRole');
        return areaRole;
    };


    // 渲染菜单 menuArr 菜单数组，url 父级url
    renderMenu = (menuArr,url) => {
        if(!isArray(menuArr)) return;
        return (
            menuArr.map(item => {
                let _url = url ? `${url}/${item.url}` : `/${item.url}`;
                let _keyUrl = url ? `${url.replace(/^\//,'').replace(/\//g,'_')}_${item.url}` : `${item.url}`;
                return item.children && item.children.length ? (
                    
                    <SubMenu
                        key={_keyUrl}
                        title={<span><Icon type={item.icon} /><span>{item.name}</span></span>}
                        onTitleClick={params => this.props.changeSubMenu(params.key)}
                    >
                        {
                            this.renderMenu(item.children,_url)
                        }
                    </SubMenu>
                ) : (
                    this.isShowMenu(item.dataIndex) ? (
                        <Menu.Item key={_keyUrl} onClick={params => this.props.changeMenuItem(params.key)}>
                            <Link to={_url}>
                                <Icon type={item.icon} />
                                <span>{item.dataIndex === 'paynotice' ? `${item.name} (${this.state.newnotic})` : item.name} </span>
                            </Link>
                        </Menu.Item>
                    ) : null
                )
            })
        )
    }



    // 判断菜单是否显示
    isShowMenu = dataIndex => {
        let bol = true;
        switch(dataIndex){
            case 'personaltool':
                if(!this.handleRank()) bol = false;
                break;
            // case 'robbing':
            //     if(!this.areaRole()) bol = false;
            //     break;
            case 'organize':
                if(!this.areaRole()) bol = false;
                break;
            // case 'followCount':
            //     if(!this.organizRank()) bol = false;
            //     break;
            // case 'quality':
            //     if(!this.organizRank()) bol = false;
            //     break;
            case 'followDayPaper':
                if(!(this.areaRoles()==='-1') ) bol = false
                break;
            case 'followCount':
                if(!(this.areaRoles()==='-1') ) bol = false
                break;
            case 'quality':
                if(!(this.areaRoles()==='-1') ) bol = false
                break;
            case 'invitation':
                if(!(this.areaRoles()==='-1') ) bol = false
                break;
            case 'track':
                if(!(this.areaRoles()==='-1') ) bol = false
                break;
            case 'list':
                if(!(this.areaRoles()==='-1') ) bol = false
                break;
            case 'queue':
                if(!(this.areaRoles()==='-1') ) bol = false
                break;
            case 'query':
            case 'robbing':
            case 'blacklist':
            case 'cancel':
            case 'followup':
            case 'warntool':
            case 'paybacklist':
                if(!(this.areaRoles()==='-1') ) bol = false
                break;
            case 'examination':
                if(!this.handleRank()) bol = false;
                break;
            case 'setting':
                if(!this.handleRank()) bol = false;
                break;
            case 'manage':
                if(this.maintain() === '0') bol = false
                break;
        }
        return bol
    }
    render(){
        return(
            <Sider
                trigger={null}
                collapsible
                collapsed={this.props.collapsed}
                width={220}
                className="overflow-y"
            >
                {/*系统的名称*/}
                {
                    this.props.collapsed ? (
                        <div className="system-icon">TBS</div>
                    ) : (
                        <div className="system-name">销售管理系统</div>
                    )
                }
                {/*导航*/}
                <Menu theme="dark" mode="inline" openKeys={this.props.menuActive.openKeys} selectedKeys={this.props.menuActive.selectedKeys}>
                    {
                        this.renderMenu(allMenu)
                    }
                </Menu>
            </Sider>
        )
    }
}
function mapStateToProps(state){
    return {
        menuActive : state.changeMenuActive
    }
}

export default connect(mapStateToProps,{
    getMsgCount,
    changeMenuActive,       // 菜单激活
    changeSubMenu,          // 展开或者收起SubMenu
    changeMenuItem,         // 选中MenuItem
})(Left);

