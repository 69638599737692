import React from 'react'
import './AddFollow.scss'
import { Modal,Form,Input,Button,Select,message,DatePicker,Checkbox} from 'antd'
import {customerTypeSetUp,coTypeSetUp} from '../../utils/config'
import {getDate} from "../../utils/common";

const Option = Select.Option;
const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;
const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 }
};


const AddFollowForm = Form.create()(class extends React.Component{
    state = {
        loading : false,
        newCustomerType:this.props.comInfo.co_type.toString(),
        RandowIndex :this.props.comInfo.invite_list &&  Math.ceil(Math.random()*this.props.comInfo.invite_list.length-1),
        followIng:[],
        callStatus:'',
        followStatus:'',
        invalidDec:'',
        //单选内容
        radioSec:[
            {
                type:1,
                dec:'开场白挂机',
                checkoutStatus:false
            },{
                type:2,
                dec:'接通后挂机',
                checkoutStatus:false
            },{
                type:3,
                dec:'接通后无应答',
                checkoutStatus:false
            },{
                type:4,
                dec:'通话中断',
                checkoutStatus:false
            },{
                type:5,
                dec:'晚点回电',
                checkoutStatus:false
            },{
                type:11,
                dec:'合作过但不想继续合作',
                checkoutStatus:false
            },{
                type:12,
                dec:'不考虑网单',
                checkoutStatus:false
            },{
                type:13,
                dec:'已和其他平台合作',
                checkoutStatus:false
            },{
                type:14,
                dec:'需求不合理',
                checkoutStatus:false
            },{
                type:99,
                dec:'其他',
                checkoutStatus:false
            },{
                type:41,
                dec:'已准备续费',
                checkoutStatus:false
            },{
                type:42,
                dec:'有续费意愿',
                checkoutStatus:false
            },{
                type:43,
                dec:'订单质量低',
                checkoutStatus:false
            },{
                type:44,
                dec:'服务态度差',
                checkoutStatus:false
            },{
                type:45,
                dec:'收费太高',
                checkoutStatus:false
            }
            //以下是之前的类型
            // {
            //     type:1,
            //     dec:'拒接',
            //     checkoutStatus:false
            // },{
            //     type:2,
            //     dec:'通话中',
            //     checkoutStatus:false
            // },{
            //     type:3,
            //     dec:'无人接听',
            //     checkoutStatus:false
            // },{
            //     type:4,
            //     dec:'关机',
            //     checkoutStatus:false
            // },{
            //     type:5,
            //     dec:'已停机',
            //     checkoutStatus:false
            // },{
            //     type:6,
            //     dec:'对方考虑中',
            //     checkoutStatus:false
            // },{
            //     type:7,
            //     dec:'已互留联系方式',
            //     checkoutStatus:false
            // },
            // {
            //     type:8,
            //     dec:'条件没谈拢',
            //     checkoutStatus:false
            // },{
            //     type:9,
            //     dec:'暂无意向',
            //     checkoutStatus:false
            // },{
            //     type:10,
            //     dec:'合作过，不考虑继续合作',
            //     checkoutStatus:false
            // },{
            //     type:11,
            //     dec:'不考虑网单',
            //     checkoutStatus:false
            // },{
            //     type:12,
            //     dec:'已和其他平台合作',
            //     checkoutStatus:false
            // }
        ],
        //多选改单选
        options:[
            { label: '对方考虑中', value: '1',checkoutStatus:false },
            { label: '已互留联系方式', value: '2',checkoutStatus:false },
            { label: '条件没谈拢', value: '3',checkoutStatus:false },
            { label: '暂无意向', value: '4',checkoutStatus:false},
            { label: '合作过,不考虑继续合作', value: '5',checkoutStatus:false },
            { label: '不考虑网单', value: '6',checkoutStatus:false },
            { label: '已和其他平台合', value: '7',checkoutStatus:false },
        ],
        //新的客户类型
        coTypeSetUp:[
            { id:'0',name:'新客户',ishow:false},
            { id:'1',name:'潜在',ishow:false},
            { id:'3',name:'意向',ishow:false},
            { id:'4',name:'成交客户',ishow:false},
            { id:'5',name:'无效',ishow:false},
            { id:'7',name:'暂无意向',ishow:false},
            { id:'8',name:'待签约',ishow:false},
            { id:'9',name:'到期会员',ishow:false},

        ]

    };

    //不显示选框的条件
    noshow=["4","5","6"];
    //无效选择
    invalid = [
        {
            type:1,
            dec:'号码错误'
        },
        {
            type:2,
            dec:'不是装修公司'
        },
        {
            type:3,
            dec:'公司倒闭'
        },
        {
            type:4,
            dec:'其他'
        },{
            type:5,
            dec:'合作中'
        },{
            type:6,
            dec:'家居/建材公司'
        },{
            type:7,
            dec:'同行登记'
        }
    ];
    //客户类型选项改变
    changeUserType=(val)=>{
        this.setState({
            newCustomerType:val.toString()
        })
    };
    //多选项的改变--跟进的描述
    followIngChange=(e)=>{
        this.setState({
            followIng:e
        })
    };

    //单选框改变--接听的状态
    callStatusChange=(indexs,type,content) => {
        content.map((item,index) => {
            if(indexs === index){
                item.checkoutStatus = !item.checkoutStatus;

            }else{
                item.checkoutStatus = false;
            }
            return ''
        });
        if(content[indexs].checkoutStatus === true){
            this.setState({
                callStatus: type
            })
        }else{
            this.setState({
                callStatus:''
            });
        }
    };
    // 客户类型可设置配置
    // customerTypeSetUp = () => {
    //     let newCustomerType = '';
    //     const getCoustomerType = this.props.comInfo.co_type.toString();
    //     switch (getCoustomerType){
    //         case '0' :
    //             newCustomerType = [{id : '0',name : '新客户'},{id : '1',name : '初步接触'},{id : '2',name : '持续跟进'},{id : '3',name : '线下跟进'},{id : '5',name : '无效客户'},{id : '6',name : '已签单客户'}];
    //             break;
    //         case '1' :
    //             newCustomerType = [{id : '1',name : '初步接触'},{id : '2',name : '持续跟进'},{id : '3',name : '线下跟进'},{id : '5',name : '无效客户'},{id : '6',name : '已签单客户'}];
    //             break;
    //         case '2' :
    //             newCustomerType = [{id : '2',name : '持续跟进'},{id : '3',name : '线下跟进'},{id : '5',name : '无效客户'},{id : '6',name : '已签单客户'}];
    //             break;
    //         case '3' :
    //             newCustomerType = [{id : '2',name : '持续跟进'},{id : '3',name : '线下跟进'},{id : '5',name : '无效客户'},{id : '6',name : '已签单客户'}];
    //             break;
    //         case '5' :
    //             newCustomerType = [{id : '1',name : '初步接触'},{id : '2',name : '持续跟进'},{id : '3',name : '线下跟进'},{id : '5',name : '无效客户'},{id : '6',name : '已签单客户'}];
    //             break;
    //         case '6' :
    //             newCustomerType = [{id : '6',name : '已签单客户'},{id : '1',name : '初步接触'},{id : '2',name : '持续跟进'},{id : '3',name : '线下跟进'},{id : '5',name : '无效客户'}];
    //             break;
    //         default :
    //             newCustomerType = []
    //     }
    //
    //     return newCustomerType;
    // };

    //提交请求的方法
    referSub = (_this,values)=>{
        this.props.addfollow(values,function(res){
            _this.setState({
                loading : false
            });
            if(res.code === 0){
                message.success(res.message,1);
                _this.props.handleCancel();
                // _this.props.closeCallWindow();
                // _this.props.ClosehandleInforBox && _this.props.ClosehandleInforBox();
                // 重新获取跟进记录和用户信息
                _this.props.getComFollow({co_id:_this.props.comInfo.co_id});
                _this.props.getComInfo({co_id:_this.props.comInfo.co_id});
                // _this.props.getCostomerList(false,1,_this.props.keys);
            }else if(res.code === 1){
                message.error(res.message,1);
            }
        })
    };
    onSubmit = (e) => {
        const _this = this;
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    loading : true
                });
                values['pre_time'] = values['pre_time'] ? getDate(values['pre_time']['_d']) : '';
                values.co_id = this.props.comInfo.co_id;
                // values['co_tag']=this.state.callStatus;
                // values['desc_tag'] = this.state.followStatus;
                //分情况判断
                // 客户类型id,0-新客户、1-潜在（初步接触）、2-持续跟进（归到1）、3-意向（线下跟进）、4-成交、5-无效、6-已签单客户（归到5）、7-暂无意向、8-待签约
                //判断是否为空
                this.referSub(_this,values)
                // if(this.noshow.indexOf(values['co_type']) !== -1){
                //     //无效原因未有填写的
                //     if(values['invalid_reason']=== ''){
                //         message.error('无效原因不能为空');
                //         _this.setState({
                //             loading : false
                //         });
                //         return false;
                //     }
                //     //为成交或无效的
                //     if(values['desc']===''){
                //         message.error('跟进情况不能为空');
                //         _this.setState({
                //             loading : false
                //         });
                //         return false;
                //     }else if(values['desc'].length<5){
                //         message.error('请填写大于5个字的说明');
                //         _this.setState({
                //             loading : false
                //         });
                //         return false;
                //     }else{
                //         this.referSub(_this,values)
                //     }
                // }else{
                //     if(values['co_tag']==='' || values['desc']==='' ){
                //         message.error('跟进情况不能为空');
                //         _this.setState({
                //             loading : false
                //         });
                //         return false;
                //     }else{
                //         this.referSub(_this,values)
                //     }
                // }
            }
        })
    };
    //默认的客户类型
    returnCoType =(type)=>{
        return async () => {
            await this.state.coTypeSetUp.map(item=>{
                ( type === 0|| type === 1 || type === 2 || type === 5 || type === 9 || type === 7 )  ? (item.id !== '0' && item.id !== '4' && item.id !== '9') && (
                    item.ishow=true
                ):(type === 3 || type === 8)  ? (item.id === '8' || item.id ==='3')  &&(
                    item.ishow=true
                ):type === 4 ? (item.id === '4') && (
                    item.ishow=true
                ) :item.ishow=false
                return ''
            })
            await this.setState({
                coTypeSetUp:this.state.coTypeSetUp
            })
        }
    }
    componentDidMount(){
        this.returnCoType(this.props.comInfo.co_type)().then()
    }


    render(){
        const { getFieldDecorator } = this.props.form;
        const comInfo = this.props.comInfo;

        return(
            <Form  onSubmit={this.onSubmit} className="add-customer-form  showFollow">
                {/*客户类型*/}
                <Form.Item
                    label="客户类型"
                    {...formItemLayout}
                >
                    {/*为到期会员（9）状态默认选项指向潜在类型*/}
                    {getFieldDecorator('co_type',{
                        initialValue : comInfo.co_type === 4 ? '4': comInfo.co_type === 5 ? '5': comInfo.co_type === 9 ? '1' :'',
                        rules: [{ required: true, message: '请选择客户类型！' }]
                    })(
                        <Select  onChange={ (val)=>this.changeUserType(val)}>
                            {
                                this.state.coTypeSetUp.map((item,index) => {
                                    return (
                                       item.ishow &&  <Option key={index} value={item.id}>{item.name}</Option>
                                    )
                                })
                            }
                        </Select>
                    )}
                </Form.Item>
                {/*客户类型为潜在,暂无意向,的时候*/}
                {
                    (this.state.newCustomerType === '1' || this.state.newCustomerType === '2' || this.state.newCustomerType === '7') ?
                    <div>
                        {
                            <Form.Item
                                label="客户标签"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('co_tag',{
                                    initialValue : '',
                                    rules: [{ required: true, message: '请选择客户标签！' }]
                                })(

                                    <Select  >
                                        <Option value=''>请选择</Option>
                                        {
                                            this.state.radioSec.map((item,index) => {

                                                return (
                                                    (this.state.newCustomerType === '1' || this.state.newCustomerType === '2') ?
                                                        (item.type <=5 || item.type ===99) && <Option key={item.type} value={item.type}>{item.dec}</Option> :
                                                    this.state.newCustomerType === '7' ?
                                                        ((item.type >=11 && item.type <=14)  || item.type ===99) && <Option key={item.type} value={item.type}>{item.dec}</Option> :''
                                                )
                                            })
                                        }
                                    </Select>

                                )}
                            </Form.Item>
                        }
                        {/*预约回访时间*/}
                        <Form.Item
                            label="预约回访"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('pre_time',{
                                initialValue : '',
                            })(
                                <DatePicker
                                    style={{ width: 278 }}
                                    placeholder="选择时间"
                                    format="YYYY-MM-DD HH:mm:ss"
                                    showTime
                                />
                            )}
                        </Form.Item>
                        {/*备注说明*/}
                        <Form.Item
                            label="备注信息"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('desc',{
                                initialValue : comInfo.desc,
                                rules: [
                                    { required: true, message: '请输入备注信息！' },
                                    {min:5,message:'请输入不小于5个字的说明'}
                                ]
                            })(
                                <div className='restRadioGroup'>
                                        <TextArea
                                            autosize={{ minRows: 2, maxRows: 6 }} maxLength="100"
                                        />
                                </div>
                            )}
                        </Form.Item>
                    </div>
                    :
                    //默认模板
                    <div>
                        {
                            //无效类型 未有客户标签，预约回访，去掉一个备注
                        }
                        {
                            this.state.newCustomerType === "5" ?
                            <div>
                                <Form.Item
                                    label="无效类型"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('invalid_reason',{
                                        initialValue : '',
                                        rules: [{ required: true, message: '请选择无效类型！' }]
                                    })(
                                        <Select  style={{ width: 278 }}>
                                            <Option value=''>请选择</Option>
                                            {
                                                this.invalid.map(item => {

                                                    return item.type === '3' ?'': <Option key={item.type} value={item.type}>{item.dec}</Option>
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </div>
                            :
                            <div>
                                {
                                    this.state.newCustomerType === '3' || this.state.newCustomerType === '8' ? '':
                                        //成交客户
                                        this.state.newCustomerType === "4" ?
                                        <Form.Item
                                            label= "客户标签"
                                            {...formItemLayout}
                                        >
                                            {getFieldDecorator('co_tag',{
                                                initialValue : '',
                                                rules: [{ required: true, message: '请选择客户标签！' }]
                                            })(
                                                <Select  >
                                                    <Option value=''>请选择</Option>
                                                    {
                                                        this.state.radioSec.map((item,index) => {
                                                            return (
                                                                item.type === 99 ||   ( item.type <= 45 && item.type >= 41 )    ?
                                                                <Option key={item.type} value={item.type}>{item.dec}</Option> :''
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            )}
                                        </Form.Item>:
                                        <Form.Item
                                            label= "客户标签"
                                            {...formItemLayout}
                                        >
                                            {getFieldDecorator('co_tag',{
                                                initialValue : '',
                                                rules: [{ required: true, message: '请选择客户标签！' }]
                                            })(
                                                <Select  >
                                                    <Option value=''>请选择</Option>
                                                    {
                                                        this.state.radioSec.map((item,index) => {
                                                            return (
                                                                <Option key={item.type} value={item.type}>{item.dec}</Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            )}
                                        </Form.Item>
                                }
                                {/*预约回访时间*/}
                                <Form.Item
                                    label="预约回访"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('pre_time',{
                                        initialValue : '',
                                    })(
                                        <DatePicker
                                            style={{ width: 278 }}
                                            placeholder="选择时间"
                                            format="YYYY-MM-DD HH:mm:ss"
                                            showTime
                                        />
                                    )}
                                </Form.Item>
                            </div>
                        }
                        {/*备注说明*/}
                        <Form.Item
                            label="备注信息"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('desc',{
                                initialValue : comInfo.desc,
                                rules: [
                                    { required: true, message: '请输入备注信息！' },
                                    {min:5,message:'请输入不小于5个字的说明'}
                                ]
                            })(
                                <div className='restRadioGroup'>
                                        <TextArea
                                            autosize={{ minRows: 2, maxRows: 6 }} maxLength="100"
                                        />
                                </div>

                            )}
                        </Form.Item>
                    </div>
                }
                {/*线下更进*/}
                {
                    this.state.newCustomerType === "3" &&  comInfo.invite_list &&
                    <Form.Item
                        label="指派给"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('invite_uid',{
                            initialValue : comInfo.invite_list.length >1 ? comInfo.invite_list[this.state.RandowIndex]['id'] :comInfo.invite_list.length === 0 ?  '当前客户没有指派人信息,无法进行线下跟进':  comInfo.invite_list[0]['id'],
                            rules: [{ required: true, message: '请选择指派人！' }]
                        })(
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(inputValue, option) => option.props.children.indexOf(inputValue) !== -1}
                                style={{ width: 278 }}>
                                {
                                    comInfo.invite_list.map(item => {
                                        return <Option key={item.id} value={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        )}
                    </Form.Item>
                }
                {/*下次跟进时间*/}
                {/*<Form.Item*/}
                    {/*label="下次跟进"*/}
                    {/*{...formItemLayout}*/}
                {/*>*/}
                    {/*{getFieldDecorator('time',{*/}
                        {/*initialValue : null,*/}
                    {/*})(*/}
                        {/*<DatePicker*/}
                            {/*placeholder="选择时间"*/}
                            {/*style={{ width: 278 }}*/}
                            {/*format="YYYY-MM-DD HH:mm:ss"*/}
                            {/*showTime*/}
                        {/*/>*/}
                    {/*)}*/}
                {/*</Form.Item>*/}


                {/*跟进情况*/}

                <Form.Item
                    style={{textAlign : 'center'}}
                >
                    <Button
                        type="ghost"
                        htmlType="button"
                        onClick={this.props.handleCancel}
                    >
                        取消
                    </Button>
                    <Button
                        loading={this.state.loading}
                        type="primary"
                        htmlType="submit"
                        style={{marginLeft : '10px'}}
                        disabled={ this.state.newCustomerType === "3" ?  comInfo.invite_list ?  comInfo.invite_list.length === 0 ?  true:false : false:false}
                    >
                        确定
                    </Button>
                </Form.Item>
            </Form>
        )
    }
});

class AddFollow extends React.Component{
    render(){
        return(
            <Modal
                title="添加记录"
                visible={this.props.visible}
                onCancel={this.props.handleCancel}
                maskClosable={false}
                footer={null}
                width={550}
                destroyOnClose={true}
                zIndex={1000}
            >
                <AddFollowForm {...this.props} />
            </Modal>
        )
    }
}


export default AddFollow

