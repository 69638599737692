// 左侧导航菜单
export const allMenu = [
    {
        name:'首页',
        url:'home',
        dataIndex:'home',
        icon:'radar-chart',
    },
    {
        name : '客户管理',
        url : 'business',
        icon : 'user',
        children : [
            {
                name : '我的客户',
                url : 'us'
            },
            {
                name:'公共客户',
                url:'common',
                dataIndex:'common'
            },
            {
                name:'续费客户管理',
                url:'manage',
                dataIndex:'manage'
            },
            {
                name:'续费客户分配',
                url:'assign'
            },
            {
                name:'综合查询',
                url:'query',
                dataIndex:'query'
            },
            {
                name:'历史记录查询',
                url:'history'
            },
            {
                name:'规则设置',
                url:'robbing',
                dataIndex : 'robbing',
            },
            {
                name : '组织架构',
                url : 'organize',
                dataIndex:'organize'
            },{
                name:'客户黑名单',
                url:'blacklist',
                dataIndex:'blacklist'
            }
        ]
    },
    {
        name:'数据统计',
        url:'dataCount',
        icon:'bar-chart',
        children:[
            {
                name:'客户跟进日报',
                url:'followDayPaper',
                dataIndex:'followDayPaper'
            },
            {
                name:'销售工作量化',
                url:'followCount',
                dataIndex:'followCount'
            },
            {
                name:'客户质量分析',
                url:'quality',
                dataIndex:'quality'
            },
            {
                name:'邀约工作量化',
                url:'invitation',
                dataIndex:'invitation'
            },
            {
                name:'邀约效果追踪',
                url:'track',
                dataIndex:'track'
            },{
                name:'排行榜',
                url:'list',
                dataIndex:'list'
            },
            {
                name : '队列数据量化',
                url : 'queue',
                dataIndex:'queue'
            }
        ]
    },
    {
        name : '商务审批',
        url : 'audit',
        icon : 'audit',
        children : [
            {
                name : '我发起的',
                url : 'my'
            },
            {
                name : '审批管理',
                url : 'approve'
            },
            {
                name:'审批查询',
                url:'examination',
                dataIndex:'examination'
            },
            {
                name:'审批配置',
                url:'setting',
                dataIndex :'setting',
            }
        ]
    },
    {
        name : '会员管理',
        url : 'member',
        icon : 'solution',
        children : [
            {
                name:'会员信息',
                url:'info'
            }
        ]
    },
    {
        name:'维护中心',
        url:'assert',
        icon:'team',
        children:[
            {
                name: '待分配订单',
                url : 'wait'
            },{
                name : '已分配订单',
                url : 'already'
            },{
                name : '撤单管理',
                url : 'cancel',
                dataIndex : 'cancel'
            },{
                name:'反馈列表',
                url:'feedback'
            },{
                name:'跟进列表',
                url:'followup',
                dataIndex : 'followup'
            },{
                name:'人员配置',
                url:'personaltool',
                dataIndex : 'personaltool'
                
            },
            // {
            //     name:'公司信息',
            //     url:'companymess'
            // },
            {
                name:'续费通知',
                url:'paynotice',
                dataIndex : 'paynotice'
            },{
                name:'预警设置',
                url:'warntool',
                dataIndex : 'warntool'
            },{
                name:'续费通知黑名单',
                url:'paybacklist',
                dataIndex : 'paybacklist'
            }
        ]
    },{
        name : '工具',
        url : 'tool',
        icon : 'tool',
        children : [
            {
                name : '订单计算器',
                url : 'compute'
            }
        ]
    }
]

//


