//获取流动数据
export function getOrderTransfer(state=[],action){
    switch (action.type){
        case 'transfer' :
            return action.data
            break;
        default :
            return state
    }
}

//查看范围(负责成员)
export function getMembers(state=[],action){
    switch (action.type){
        case 'MEMBERS' :
            return action.data
            break;
        default :
            return state
    }
}
//查看范围(区域信息)
export function getAreas(state=[],action){
    switch (action.type){
        case 'AREAS' :
            return action.data
            break;
        default :
            return state
    }
}



// 组织架构
// 获取组织架构信息
export function getOrganList(state=[],action) {
    switch (action.type) {
        case 'ORGAN_LIST' :
            return action.data;
            break;
        case 'ORGAN_EMPTY' :
            return state;
            break;
        default :
            return state;
            break;
    }
}

//获取负责人信息
export function getJobList(state=[],action){
    switch (action.type){
        case 'JOB_LIST' :
            return action.data
            break;
        default :
            return state
    }
}
//获取所有人员信息
export function getAllStaff(state=[],action) {
    switch (action.type){
        case 'AllStaff' :
            return action.data
            break;
        default :
            return state
    }
}

//获取邀约指派人列表
export function getInviteStaff(state=[],action) {
    switch (action.type){
        case 'InviteStaff' :
            return action.data
            break;
        default :
            return state
    }
}

