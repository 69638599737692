import Request from '../fetch'
const {post,get} = Request

export function invitestats(obj) {
    return async dispath=>{
        let res = await get('stats/getcallandinvitestats',obj);
        return res
    }
}



