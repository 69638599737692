import { isFunction } from "../utils/common";
import Request from '../fetch'
const {post,get} = Request

// 跟进记录列表
export function getFollowList(obj,call) {
    return dispath => {
        post('sale/getfollowlist',obj).then(res => {
            isFunction(call) && call(res)
        })
    }
}

// 呼叫历史列表
export function getCallHistory(obj,call){
    return dispath => {
        post('call/history',obj).then(res => {
            isFunction(call) && call(res)
        })
    }
}