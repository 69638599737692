import React from 'react'

import { Form,Input,Button,message } from 'antd'

class LoginBoxForm extends React.Component {
    handleSubmit = (e) => {
        const _this = this;
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                message.loading('登录中',0)
                this.props.doLogin(values,function(res){
                    message.destroy();
                    if(res.code === 0){
                        localStorage.setItem('token',res.data.token);
                        localStorage.setItem('userId',res.data.userinfo.id);
                        sessionStorage.setItem('userRank',res.data.userinfo.rank);
                        sessionStorage.setItem('organRole',res.data.userinfo.organ_role);
                        sessionStorage.setItem('areaRole',res.data.userinfo.organ_area_ids);

                        sessionStorage.setItem('agentID',res.data.userinfo.agent_id);
                        sessionStorage.setItem('agentPWD',res.data.userinfo.agent_pwd);
                        sessionStorage.setItem('employee_id',res.data.userinfo.id);
                        sessionStorage.setItem('is_maintain',res.data.userinfo.is_maintain)
                        message.success(res.message,1,function () {
                            _this.props.history.push('/')
                        })
                    }else{
                        message.error(res.message,1)
                    }
                })
            }
        });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit}>
                {/*用户名*/}
                <Form.Item>
                    {getFieldDecorator('username', {
                        rules: [{ required: true, message: '请输入用户名' }],
                    })(
                        <Input
                            placeholder="用户名"
                            className="form-item" />
                    )}
                </Form.Item>
                {/*密码*/}
                <Form.Item>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: '请输入密码' }],
                    })(
                        <Input
                            placeholder="密码"
                            type="password"
                            className="form-item" />
                    )}
                </Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="form-btn"
                >登录</Button>
            </Form>
        )
    }
}

const LoginBox = Form.create()(LoginBoxForm);

export default LoginBox;