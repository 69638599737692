import React from 'react'

import './index.scss'

import LoginBox from './loginBox'


import { connect } from 'react-redux'
import {
    doLogin,checkToken
} from '../../actions'

class Login extends React.Component {
    // componentDidMount = () => {
    //     // 已登录就跳转登录页面
    //     if(localStorage.getItem('token')){
    //         this.props.checkToken(res => {
    //             if(res.code === 0){
    //                 this.props.history.push('/')
    //             }
    //         })
    //     }
    // }
    render() {
        return (
            <div className="login">
                <div className="login-con">
                    <div className="login-form">
                        <div className="title">销售管理系统</div>
                        {/*登录框*/}
                        <LoginBox {...this.props} />
                    </div>
                    <div className="company-code">©2018  浙江土拨鼠网络科技有限公司  TOBOSU.COM</div>
                </div>
            </div>
        )
    }
}

export default connect(null,{
    doLogin,
    checkToken
})(Login);