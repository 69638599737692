export function getUserCountInfo(state={},action){
    switch (action.type){
        case 'comInfo' :
            return action.data
            break;
        default :
            return state
    }
}

export function getAllCallGroup(state=[],action) {
    switch (action.type){
        case 'ALL_CALL_GROUP' :
            return action.data
            break;
        default :
            return state
    }
}