// 改变框架左侧菜单选中状态
export function changeMenuActive(state = {openKeys:[],selectedKeys:[]},action) {
    switch (action.type){
        case 'MENU_ACTIVE' :    // 左侧菜单激活，一般用于首次加载
            return action.data;
        case 'SUB_MENU' :       // 改变展开的SubMenu
            const { openKeys } = state
            const index = openKeys.indexOf(action.subMenu)
            // 通过判断openKeys是否存在SubMenu的key判断SubMenu展开或者收起
            index === -1 ? openKeys.push(action.subMenu) : openKeys.splice(index,1)
            return state
        case 'MENU_ITEM':       // 改变选中的MenuItem
            const { selectedKeys } = state
            selectedKeys.splice(0,1,action.menuItem)
            return state
        default :
            return state
    }
}

export function getNameList(state = [],action){
    switch (action.type){
        case 'nameList' :
            return action.data;
        default :
            return state
    }
}

// 权限城市列表
export function getMaintainCityList(state=[],action){
    switch (action.type){
        case 'MAINTAUNT_CITY' :
            return action.data;
        default :
            return state
    }
}

//header未读消息当前
export function getNotifyNum(state='',action){
    switch (action.type) {
        case 'HEADER_NUM':
            return action.data
        case 'NOTIFY_TOTAL':
            return action.data
        default :
            return state
    }
}


//存储header未读消息
export function setNotifyTotal(state='',action) {
    switch (action.type) {
        case 'NOTIFY_TOTAL':
            return action.data
        default :
            return state
    }
}

//存储消息列表
export function getNotifyList(state=[],action) {
    switch (action.type) {
        case 'NOTIFY_LIST':
            return action.data
        default :
            return state
    }
}

// 获取用户开发大区
export function getUserAreaList(state=[],action){
    switch (action.type){
        case 'userAreaList' :
            return action.data;
        default :
            return state
    }
}