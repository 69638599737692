// 客户中的actions
import { isFunction } from "../utils/common";
import Request from '../fetch'
const {post} = Request


export function doLogin(obj,call){
    return () => {
        post('login/dologin',obj).then(res => {
            isFunction(call) && call(res)
        })
    }
} 

export function doLogout(call){
    return () => {
        post('login/dologout').then(res => {
            isFunction(call) && call(res)
        })
    }
}

// 获取会员信息
export function getUserInfo(call){
    return dispath => {
        post('user/getinfo').then(res => {
            if(res.code === 0){
                dispath({
                    type : 'userInfo',
                    data : res.data
                })
            }
            isFunction(call) && call(res)
        })
    }
}


// 验证token
export function checkToken(call){
    return () => {
        post('public/checkToken').then(res => {
            isFunction(call) && call(res)
        })
    }
}




