// 客户中的actions
import { isFunction } from "../utils/common";
import Request from '../fetch'
const {post,get} = Request

// 查询客户列表
export function getComList(obj,call) {
    return dispath => {
        get('sale/getcomlist',obj).then(res => {
            isFunction(call) && call(res)
        })
    }
}


// 获取客户详细信息
export function getComInfo(obj,call){
    return dispath => {
        post('sale/getcominfo',obj).then(res => {
            if(res.code === 0){
                dispath({
                    type : 'comInfo',
                    data : res.data
                })
            }
            isFunction(call) && call(res)
        })
    }
}

export function delComInfo(){
    return dispath => {
        dispath({
            type : 'delComInfoRex'
        })
    }
}



// 预约时间请求接口 暂时不用
// export function setCompreTime(obj,call){
//     return dispath => {
//         fetchApi('sale/setcompretime',obj).then(res => {
//             isFunction(call) && call(res);
//         })
//     }
// }


// 添加客户接口 (暂不用)
export function saveCom(obj,call){
    return dispath => {
        post('sale/savecom',obj).then(res => {
            isFunction(call) && call(res);
        })
    }
}

// 编辑客户信息
export function editCom(obj) {
    return async dispath=>{
        let res = await post('sale/editCom',obj);
        return res;
    }
}

// 新增客户信息
export function getaddCom(obj) {
    return async dispath=>{
        let res = await post('sale/addCom',obj);
        return res;
    }
}

// 获取客户跟进记录接口
export function getComFollow(obj){
    return dispath => {
        get('sale/getcomfollow',obj).then(res => {
            if(res.code === 0){
                dispath({
                    type : 'comFollow',
                    data : res.data
                })
            }if(res.code === 1){
                dispath({
                    type : 'comFollow',
                    data : []
                })
            }
        }).catch(error => {
            console.log(error)
        })
    }
}

// 添加跟进记录
export function addfollow(obj,call){
    return dispath => {
        post('sale/addfollow',obj).then(res => {
            isFunction(call) && call(res)
        })
    }
}

// 获取操作记录(暂不删除，更新了此接口↓)
export function getOperateList(obj,call){
    return dispath => {
        post('sale/getoperatelist',obj).then(res => {
            isFunction(call) && call(res)
        })
    }

}

//新获取操作纪录
export function getComOperateList(obj) {
    return dispath =>{
        get('sale/getComOperateList',obj).then(res=>{
            if(res.code === 0){
                dispath({
                    type:'operate',
                    data:res.data.list ? res.data.list : []
                })
            }
        })
    }
}

// 删除操作
export function batchDelCom(obj,call){
    return dispath => {
        post('sale/batchdelcom',obj).then(res => {
            isFunction(call) && call(res)
        })
    }
}

//
