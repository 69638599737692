import React , {Component} from 'react';
import {Switch,Route} from 'react-router-dom'

// 页面组件

import Container from '../container/index'
import Login from '../pages/login'

class RouterMap extends Component{
    render(){
        return(
            <div className="wrap">
                <Switch>
                    <Route exec path="/login" component={Login} />
                    <Route exec path="/" component={Container} />
                </Switch>
            </div>
        )
    }
}

export default RouterMap;