//获取部门信息
export function getDepartment(state=[],action){
    switch (action.type){
        case 'deptList' :
            return action.data
            break;
        default :
            return state
    }
}

// 2018/10/31 新增分单、撤单逻辑
// 获取分单配置信息
export function getDivideConfig(state={},action) {
    switch (action.type) {
        case 'DIVIDE_CONFIG' :
            return action.data;
        default :
            return state;
    }
}
