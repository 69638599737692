/*
 * title  : 土拨鼠后台管理系统共用函数
 * author : 曾庆标
 * email  : 1094926084@qq.com
 * time   : 2018/7/2
 */
export function isArray(o){
    return Object.prototype.toString.call(o)=='[object Array]';
}

export function isDefined(t) {
    return "undefined" !== typeof t
}

export function isFunction(t) {
    return "function" == typeof t
}

export function isObject(t) {
    return "object" == typeof t
}

export function isString(t) {
    return "string" == typeof t || t instanceof String
}

// 获取连接上带的参数
export function getUrlParam(link,name) {
    let reg = new RegExp(`${name}=([^&]*)(&|$)`),
        reg2 = new RegExp(`(^${name}=)|(&|$)`,"g"),
        r = link.match(reg),
        getValue = "";
    if (r != null) {
        getValue = r[0].replace(reg2, '');
    }
    return getValue;
}

// 解析url的location.pathname参数，得到当前的链接
export function getPathname(pname) {
    const getPname = pname.replace(/^\//,'')
    return getPname.split('/')
}

// 判断数字的位数
export function judgeNumberDigit(num) {
    let numString = num.toString()
    return numString.length<2 ? ('0' + numString) : numString
}


// antd design 日历控件转换成需要的日期格式（ 年-月-日  时-分-秒 ）d : antd收集机制等到的日期，bol : true为(YYYY-MM-DD)，false||不传为(YYYY-MM-DD hh:mm:ss)
export function getDate(d,bol){
    const time = new Date(d);
    let needTime = time.getFullYear() + '-' + judgeNumberDigit(time.getMonth() + 1) + '-' + judgeNumberDigit(time.getDate());
    if(!bol){
        needTime += ' ' + judgeNumberDigit(time.getHours()) + ':' + judgeNumberDigit(time.getMinutes()) + ':' + judgeNumberDigit(time.getSeconds());
    }
    return needTime
}

// 文件下载
export function downloadFile(url) {     
    try{   
        var elemIF = document.createElement("iframe");     
        elemIF.src = url;     
        elemIF.style.display = "none";     
        document.body.appendChild(elemIF);  
    }catch(e){   
        console.log(e)
    }   
} 
//默认时间段
export function  getNowFormatDate(bol){
    var date = new Date();
    //
    var seperator1 = "-";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    var hour = bol ? '00' : '23';
    var min = bol ? '00' : '59';
    var sec = bol ? '00' : '59';
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }

    var currentdate = year + seperator1 + month + seperator1 + strDate + ' ' + hour+':'+min+':'+sec;
    return currentdate;
}

//new 获取时间段
//参数说明：startTime ： 处理的起始时间，type:处理时间的方式（按月，按年） pitMoth ---> 产品理解的上个月指的是上个月的1号到上个月的末尾 ╮(╯▽╰)╭
export function getNewTimeDate(startTime,type,pitMoth) {
    //日期从当天时间往前推
    //3天前，7天，月，年
    let result = {},
        Months = [4,6,9,11],
        currentMonth =  startTime.getMonth()+1,
        currentYear = startTime.getFullYear(),
        currentDate = startTime.getDate(),
        getDays = startTime.getDay(),
        days = currentYear % 4 === 0 ? 29 :28,
        //判断大月小月
        currentDays =   Months.indexOf(currentMonth) === -1 ?  currentMonth ===2 ? days : 31 : 30,
        prevDays =  Months.indexOf(currentMonth-1) === -1 ?  currentMonth-1 ===2 ? days : 31 : 30   ,
        strMonth  = ['一','二','三'],
        stryear =['半年','一年'],
        numberMonth = ['1','2','3'], //处理 strMonth输入中文的1,2,3转换
        newType ='',
        prevMonth='',
        prevYear="";
        if(typeof type === 'number'){
        //天
            let dates = new Date(startTime.getTime()-type*24*60*60*1000);
             result={
                startTime:`${dates.getFullYear()}${'-'}${dates.getMonth()+1}${'-'}${dates.getDate()}`,
            }
        }else{
        //月
            strMonth.map((item,index)=>{
                if(strMonth[index] === type.substr(0,1)){
                    newType=numberMonth[index];
                }
            });
            let resMonth = currentMonth -newType;
            if (resMonth <= 0) {
                prevMonth= 12 + resMonth
                prevYear=currentYear-1
            } else {
                prevMonth = resMonth
                prevYear = currentYear
            }
            
            //根据坑爹的业务需求 （上个月为:2018-11.1至2018-11.30） pitMoth:上月何种状态显示
            pitMoth === true ?
            result={
                startTime:`${prevYear}${"-"}${prevMonth}${"-"}${currentDate}`,
            } : result={
                startTime:`${prevYear}${"-"}${prevMonth}${"-"}${'1'}`
            }
        //年
        }

            result ={
                startTime:result.startTime,
                endTime : pitMoth === true ? startTime : `${prevYear}${"-"}${prevMonth}${"-"}${prevDays}`
            }

            return result;

}



// 获取antd所需要的时间段
export function getTimeQuantum(time){
    let
        bigMouth = [1,3,5,7,8,10,12],
        date = new Date(),
        year = date.getFullYear(),
        mouth = date.getMonth() + 1,
        day = date.getDate(),
        hour = date.getHours(),
        min = date.getMinutes(),
        sec = date.getSeconds(),
        leapYear = year % 4 ? 29 : 28,
        prevMouth = mouth === 1 ? 12 : mouth - 1,
        sYear = day < time+1 && mouth === 1 ? year -1 : year,
        sMouth = day < time+1 ? (mouth === 1 ? 12 : mouth - 1) : mouth,
        sDay = day < time+1 ? (bigMouth.indexOf(prevMouth) === -1 ? (prevMouth === 2 ? leapYear - time + day : 30 - time + day) : 31- time + day) : day-time,
        sHour = '00',
        sMin = '00',
        sSec = '00';
    return {
        start : `${sYear}-${sMouth}-${sDay} ${sHour}:${sMin}:${sSec}`,
        end : `${year}-${mouth}-${day} ${hour}:${min}:${sec}`
    }
}

// 拼接请求的参数
export function joinRequestParams(obj) {
    const PARAMS_ARRAY = [];
    //拼接参数
    Object.keys(obj).forEach(key => PARAMS_ARRAY.push(key + '=' + obj[key]))
    return PARAMS_ARRAY.join('&')
}

// 拷贝
export function extend( obj , deep ) {
    if("object" != typeof obj) return;
    let o = obj instanceof Array ? new Array() : new Object();
    for(let k in obj ) {
        let val = obj[k];

        if(deep && "object" === typeof val){
            o[k] = extend(val,deep);
        }else{
            o[k] = val;
        }
    }
    return o;
}

// 获取图片的宽高
export function getNatural (src) {
    let image = new Image();
    image.src = src;
    return {width: image.width, height: image.height,src : src};
}


export function setSection(key, value) {
    var obj = { "key": value };
    sessionStorage.setItem(key, JSON.stringify(obj));
};
//获取section
export function getSection(key) {
    var str = sessionStorage.getItem(key);
    if (str) {
        return JSON.parse(str)["key"];
    }
    return 0;
};
//移除sectionstorage
export function removeSection(key) {
    if (arguments.length) {
        sessionStorage.removeItem(key);
    } else {
        sessionStorage.clear();
    }
};
// 快速时间查询 times : 时间段 end : 结束时间
export function fastTimeQuery(slot,end) {
    let getTime = new Date().getTime();
    let endSlot = end ? end : 1;
    return {
        start : getNeedDate(slot,getTime),
        end : getNeedDate(endSlot,getTime)
    }
}
// 获取需要的年月日 slot : 几天 ，current : 当前时间戳
export function getNeedDate(slot,current) {
    let newDate = new Date(current - slot * 86400000)
    return `${newDate.getFullYear()}-${newDate.getMonth()+1}-${newDate.getDate()}`
}
