import { isFunction } from '../utils/common'
import Request from '../fetch'
const {post,get} = Request

//查询user列表
export function getsaleList(obj,call){
    return dispath=>{
        get('stats/getsalelist',obj).then(res => {
            isFunction(call) && call(res)
        })
    }
}

//获取用户详情信息
export function getUserCountInfo(obj,call){
    return dispath=>{
        post('stats/getsaledetaillist',obj).then(res => {
            isFunction(call) && call(res)
        })
    }
}
// export function getUserCountInfo(obj){
//     return dispath=>{
//         post('stats/getsaledetaillist',obj).then(res=>{
//             if(res.code === 0){
//                 dispath({
//                     type:'comInfo',
//                     data:res.data
//                 })
//             }
//         })
//     }
// }

// 获取所有呼叫分组
export function getAllCallGroup(obj) {
    return async dispath => {
        let res = await post('stats/getAllCallGroup',obj)
        if(res.code === 0){
            dispath({
                type : 'ALL_CALL_GROUP',
                data : res.data
            })
        }
        return res
    }
}

// 获取今日量化数据
export function getTodayCallData(obj) {
    return async dispath => {
        let res = await post('stats/getTodayCallData',obj)
        return res
    }
}

// 获取历史量化数据
export function getAllCallData(obj) {
    return async dispath => {
        let res = await post('stats/getAllCallData',obj)
        return res
    }
}

//