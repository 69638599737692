import { isFunction } from '../utils/common'

import Request from '../fetch'
const {post,get} = Request

//反馈列表
export function getfeedBack(obj,call){
    return dispath=>{
        post('feedback/index',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}
//反馈记录
export function getfeeDetail(obj,call){
    return dispath =>{
        get('feedback/detail',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}

//回复反馈
export function setReply(obj,call){
    return dispath=>{
        post('feedback/reply',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}

//订单详情
export function getDetails(obj,call){
    return dispath=>{
        post('order/detail',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}

// 修改订单详情为异步函数 2018/11/1
export function getOrderDetail(obj) {
    return async dispath => {
        let res = await post('order/detail',obj)
        return res
    }
}

//跟进列表
export function getfollowUpList(obj,call){
    return dispath=>{
        post('feedback/followUpList',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}


//要求更进
export function getfollowup(obj,call){
    return dispath=>{
        post('feedback/followup',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}

//查看
export function seeTime(obj,call){
    return dispath=>{
        get('feedback/recordSeeTime',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}

//关闭反馈
export function feedcloses(obj,call){
    return dispath=>{
        get('feedback/close',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
} 


//查询人员配置列表
export function getPerList(obj,call) {
    return dispath => {
        get('maintenance/staffingList',obj).then(res => {
            isFunction(call) && call(res)
        })
    }
}
//添加人员信息查询
export function getaddPerList(obj,call){
    return dispath=>{
        get('maintenance/updateStaffingList',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}

//获取部门信息
export function getDepartment(){
    return dispath=>{
        post('department/deptList').then(res => {
            if(res.code === 0){
                dispath({
                    type : 'deptList',
                    data : res.data
                })
            }
        })
    }
}
//获取部门下的员工
export function getEmployee(obj,call){
    return dispath=>{
        post('employee/employeeList',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}

//删除人员配置
export function Removeper(obj,call){
    return dispath=>{
        get('maintenance/delStaffing',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}

//获取公司信息
export function getCompany(obj,call){
    return dispath=>{
        post('company/index',obj).then(res => {
            isFunction(call) && call(res)
        })
    }
}
//添加人员配置
export function getaddSta(obj,call){
    return dispath=>{
        get('maintenance/updateStaffing',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}

//接单范围
export function getReceive(obj,call){
    return dispath=>{
        post('company/newReceiveOrderList',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}
//分单记录
export function getRecord(obj,call){
    return dispath=>{
        post('company/orderRecord',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}

// 获取预警设置列表
export function getWarningList(obj,call) {
    return dispath => {
        post('renew/earlyWarningList',obj).then(res => {
            isFunction(call) && call(res)
        })
    }
}
//修改预警值
export function getRenew(obj,call){
    return dispath=>{
        get('renew/editEarlyWarning',obj).then(res =>{
            isFunction(call) && call(res)
        })
    }
}

//续费通知列表
export function getRenewIndex(obj,call){
    return dispath=>{
        get('renew/index',obj).then(res =>{
            isFunction(call) && call(res)
        })
    }
}
//续费通知数量
export function getMsgCount(obj,call){
    return dispath=>{
        get('renew/msgCount',obj).then(res =>{
            isFunction(call) && call(res)
        })
    }
}
//黑名单列表
export function getBlackList(obj,call){
    return dispath=>{
        get('renew/blackList',obj).then(res =>{
            isFunction(call) && call(res)
        })
    }
}
//添加黑名单列表信息
export function addBlackList(obj,call){
    return dispath=>{
        get('renew/addCompanyBlackList',obj).then(res =>{
            isFunction(call) && call(res)
        })
    }
}
//添加黑名单
export function addBlack(obj,call){
    return dispath=>{
        get('renew/addBlackList',obj).then(res=>{
            isFunction(call) && call(res)
        })
    }
}

//删除黑名单
export function delBlackList(obj,call){
    return dispath=>{
        get('renew/delBlackList',obj).then(res =>{
            isFunction(call) && call(res)
        })
    }
}

// 2018/10/31 新增分单、撤单逻辑
// 分单配置
export function getDivideConfig(){
    return async dispath => {
        let res = await post('divide/config')
        if(res.code === 200){
            dispath({
                type : 'DIVIDE_CONFIG',
                data : res.data
            })
        }
        return res
    }
}
// 获取待分单列表
export function getDivideOrders(obj) {
    return async dispath => {
        let res = await post('divide/getDivideOrders',obj)
        return res
    }
}

// 不可分操作
export function getNonDivide(obj) {
    return async dispath => {
        let res = await post('divide/bukefen',obj)
        return res
    }
}

// 获取分单公司
export function getDivideCompany(obj) {
    return async dispath => {
        let res = await post('divide/getDivideCompany',obj)
        return res
    }
}

// 分单操作
export function getDoDivide(obj) {
    return async dispath => {
        let res = await post('divide/dofendan',obj)
        return res
    }
}

// 撤单
// 获取撤单公司
export function getCancelDetail(obj) {
    return async dispath => {
        let res = await post('divide/cancelDetail',obj)
        return res
    }
}
// 申请撤单
export function getCancelApply(obj) {
    return async dispath => {
        let res = await post('divide/cancelApply',obj)
        return res
    }
}

// 撤单管理
export function getCancelList(obj) {
    return async dispath => {
        let res = await post('divide/cancelList',obj)
        return res
    }
}
// 撤销申请
export function getBackCancelOrder(obj) {
    return async dispath => {
        let res = await post('divide/backCancelOrder',obj)
        return res
    }
}
// 撤单详情页
export function getBusinessCancelDetail(obj) {
    return async dispath => {
        let res = await post('divide/businessCancelDetail',obj)
        return res
    }
}