import { combineReducers } from 'redux'

import * as customerReducers from './customerReducers'
import * as memberReducers from './memberReducers'
import * as publicReducers from './publicReducers'
import * as countReducers from './countReducers'
import * as assertReducers from './assertReducer'
import * as businessReducer from './businessReducer'
import * as auditReducers from './auditReducers'



export default combineReducers({
    ...customerReducers,
    ...memberReducers,
    ...publicReducers,
    ...countReducers,
    ...assertReducers,
    ...businessReducer,
    ...auditReducers
})