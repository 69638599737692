import Request from '../fetch'
const {post,get} = Request

// 获取审批流程审核人列表
export function getWfNodeList(obj) {
    return async dispath => {
        let res = await get('approve/wfNodeList',obj)
        return res
    }
}

// 检测会员ID和获取审批节点信息
export function checkComid(obj) {
    return async dispath => {
        let res = await get('approve/checkcomid',obj)
        return res
    }
}

// 获取接单信息
export function getReceiveOrderList(obj) {
    return async dispath => {
        let res = await get('approve/receiveOrderList',obj)
        return res
    }
}

// 保存合同审批信息
export function saveContract(obj) {
    return async dispath => {
        let res = await post('approve/saveContract',obj)
        return res
    }
}

// 获取我发起的列表
export function getApplyList(obj) {
    return async dispath => {
        let res = await get('approve/applyList',obj)
        return res
    }
}

//获取审批查询列表
export function getAllList(obj) {
    return async dispath => {
        let res = await get('approve/allList',obj)
        return res
    }
}

// 获取审批管理列表
export function getAuditList(obj) {
    return async dispath => {
        let res = await get('approve/searchList',obj)
        return res
    }
}

// 获取审批详情  这是合同和收款详情公用的接口,主要用来渲染详情页
export function getExampleMsg(obj) {
    return async dispath => {
        let res = await get('approve/exampleMsg',obj)
        return res
    }
}



// 获取合同审批详情 做重新申请合同审批的数据回写
export function getContractMsg(obj) {
    return async dispath => {
        let res = await get('approve/contractMsg',obj)
        return res
    }
}

// 获取收款审批详情 做重新申请收款审批的数据回写
export function getReceiptMoneyMsg(obj) {
    return async dispath => {
        let res = await get('approve/receiptMoneyMsg',obj)
        return res
    }
}

// 保存收款审批信息
export function saveReceipt(obj) {
    return async dispath => {
        let res = await post('approve/saveReceipt',obj)
        return res
    }
}

// 审批操作（同意、不同意、撤回）
export function getChangeAuditStatus(obj) {
    return async dispath => {
        let res = await post('approve/changeAuditStatus',obj)
        return res
    }
}
// 审批配置
export function getWfList(obj) {
    return async dispath => {
        let res = await get('config/getWfList',obj)
        return res
    }
}
// 编辑审批配置
export function setEditWf(obj) {
    return async dispath => {
        let res = await post('config/editWf',obj)
        return res
    }
}

// 获取审批配置基础信息
export function getWfInfo(obj) {
    return async dispath => {
        let res = await get('config/getWfInfo',obj)
        return res
    }
}

//获取审批流程设计信息
export function getWfProcess(obj) {
    return async dispath => {
        let res = await get('config/getWfProcess',obj)
        return res
    }
}

//获取所有人员选择（组织架构新添接口,但组织架构有同类接口已用，故不放在businessAction.js中,此接口在审批配置项增加新的引用）
export function SectoralStructure(obj) {
    return async dispath => {
        let res = await get('organ/sectoralStructure',obj)
        if(res.code === 0){
            dispath({
                type : 'SECTORAL_MESS',
                data:res.data.list
            })
        }
        return res;
    }
}

//停用审批配置项
// export function saveWfProcess(obj) {
//     return async dispath => {
//         let res = await post('config/saveWfProcess',obj)
//         return res
//     }
// }

// 保存审批流程信息
export function saveWfProcess(obj) {
    return async dispath => {
        let res = await post('config/saveWfProcess',obj)
        return res
    }
}
//停用审批类型
export function stopControlWf(obj) {
    return async dispath => {
        let res = await post('config/controlWf',obj)
        return res
    }
}

// 获取审批类型   --- 接口后加上的，审批类型在商务审批中引用
export function getWfs(obj) {
    return async dispath => {
        let res = await get('config/getWfs',obj)
        return res
    }
}

//催办
export function pressAudit(obj) {
    return async dispath => {
        let res = await post('approve/pressAudit',obj)
        return res
    }
}

//是否催办
export function isPress(obj) {
    return async dispath => {
        let res = await post('approve/isPress',obj)
        return res
    }
}

export function addComment(obj) {
    return async dispath => {
        let res = await post('approve/addComment',obj)
        return res
    }
}





