// 客户中的actions
import { isFunction } from "../utils/common";
import Request from '../fetch'
const {postM} = Request

// 获取省份信息
export function getProvince(call) {
    return dispath => {
        postM('query_order_price/get_province').then(res => {
            isFunction(call) && call(res)
        })
    }
}

// 获取城市列表
export function getAllCity(obj,call){
    return dispath => {
        postM('query_order_price/get_all_city',obj).then(res => {
            isFunction(call) && call(res)
        })
    }
}

// 获取区县
export function getDistrict(obj,call) {
    return dispath => {
        postM('query_order_price/get_district_by_city_id',obj).then(res => {
            isFunction(call) && call(res)
        })
    }
}

// 订单查价页初始化
export function orderCalcInit(obj,call) {
    return dispath => {
        postM('query_order_price/get_init_info_by_dis_id',obj).then(res => {
            isFunction(call) && call(res)
        })
    }
}

// 获取订单单价
export function getOrderUnitPrice(obj,call) {
    return dispath => {
        postM('query_order_price/calc_receive_order_unit_price',obj).then(res => {
            isFunction(call) && call(res)
        })
    }
}