import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';

// 静态资源
import './css/style.scss'


// 汉化
import { LocaleProvider } from 'antd'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import 'moment/locale/zh-cn';

// 状态管理
import { createStore,applyMiddleware } from 'redux';
import { Provider } from "react-redux";
import thunk from 'redux-thunk';

import reducers from './reducers'

const store = createStore(reducers,applyMiddleware(thunk));


ReactDOM.render(
    <Provider store={store}>
        <LocaleProvider locale={zh_CN}>
            <App />
        </LocaleProvider>
    </Provider>,
    document.getElementById('root')
);

if (module.hot) {
    module.hot.accept();
}

// registerServiceWorker();
