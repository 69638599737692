import React from 'react'
import {Layout, message, Modal} from 'antd';

import './container.scss'


// 框架组件
import Contents from './contents';
import Left from './left'
import Top from './header'

//外呼弹窗
import CallOpenWindow from './callwindow';
import { connect } from 'react-redux'
import {
    doLogout,
    getUserInfo,
    checkToken,
    getComInfo,
    getComFollow,
    getOrderTransfer,
    getOperateList,
    getProvinceList,
    phoneNumber,
    bindAxb,
    saveCom,
    addfollow,
    getOrder,
    getCityList,
    getCountyList,
    nobindAxb,
    getComOperateList,
    getInviteStaff, editCom

} from '../actions'
import AddFollow from "../components/AddFollow/AddFollow";
import GetOrderWindow from "../components/GetOrderWindow/GetOrderWindow";
import batchDialing from 'batchDialing'
import {removeSection} from "../utils/common";

class Index extends React.Component{
    getPathname = new Array
    state = {
        collapsed: false,
        showCallOpenWindow:false,//外呼弹屏
        co_id : 0,
        iconLoading:false, //点击领取时的按钮icon
        showAddFollow:false,
        showOrderWindow:false,
        selectedRowKeys:[],
        callOpenWindowLoading : false,
        inlet:'public',
        sureIcon:false
    }
    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    componentDidMount(){
        removeSection('isAlert');
        const _this = this;
        // 没有登录到login
        this.props.checkToken();
        // 判断是否有坐席
        batchDialing.reactCallOpen = function () {
            // 触发外呼弹屏
            _this.setState({
                co_id : batchDialing.co_id,
                showCallOpenWindow : true,
                callOpenWindowLoading : true
            })
            batchDialing.reactCallClose = batchDialing.reactCallClose
            //获取客户详情信息
            _this.getComInfoAndFollow(batchDialing.co_id);
        }

        // _this.setState({
        //     co_id : '',
        //     showCallOpenWindow : true,
        // })
        // _this.getComInfoAndFollow('');
    }

    //==============================外呼弹屏========================================

    // 请求客户的详细信息和跟进记录
    getComInfoAndFollow = (id) => {

        this.props.getComInfo({co_id :id},() => {
            this.setState({
                callOpenWindowLoading : false
            })
        });
        this.props.getComFollow({co_id : id});
        // this.props.getOrderTransfer({co_id:id});
        this.props.getComOperateList({co_id : id});
        this.props.getInviteStaff({co_id : id});
    };

    menuTab = [
        {
            dataindex : 'follow',
            name : '跟进记录'
        },
        // {
        //     dataindex : 'revise',
        //     name : '修改信息'
        // },{
        //     dataindex : 'record',
        //     name : '操作记录'
        // },
        {
            dataindex : 'transfer',
            name : '客户操作记录'
        }
    ];

    // 点击打开详情页领取弹窗
    handleGrabBtn=(coid)=>{
        // let len = '';
        // let phoneArr =[]


        this.setState({
            showOrderWindow:true
        })

        // len >0 ?
        //     (
        //         this.props.comInfo.co_id === coid &&
        //         phoneArr.push(this.props.comInfo.real_phone),
        //             this.setState({
        //                 iconLoading:true
        //             }),
        //             this.props.bindAxb({
        //                 telA:phoneArr,
        //                 type:'receive',
        //                 flag:this.state.inlet
        //             }).then(res=>{
        //                 if(res.code === 0){
        //                     this.setState({
        //                         iconLoading:false,
        //                         showOrderWindow:true
        //                     })
        //                 }else{
        //                     this.setState({
        //                         iconLoading:false,
        //                     })
        //                     message.error(res.message);
        //                 }
        //             })
        //
        //     ):

    };

    //外呼弹屏关闭
    handleCancel=()=>{
        this.setState({
            showCallOpenWindow:false
        })
    };


    //===================================添加跟进记录=============================================

    // 添加跟进记录弹窗控制
    handleAddFollow = () => {
        this.setState({
            showAddFollow : !this.state.showAddFollow
        })
    };
    // 领取提示框
    closeOrderWindow =()=>{
        this.setState({
            showOrderWindow:false
        })
    };
    //确认领取
    sureOrder = ()=>{
        const _this = this;
        let len = '';
        let phoneArr =[]
        //整合参数
        const param  = {
            ids : [this.props.comInfo.co_id]
        };
        this.setState({
            sureIcon:true
        })
        //请求小号接口
        this.props.phoneNumber({}).then(res=>{
            len=res.code === 0 ? res.data : '';
            if(len >param.ids.length){
                this.setState({
                    iconLoading:true
                });
                for(let i = 0 ; i < param.ids.length;i++){
                    let queryVal = _this.state.comList.find(value=>value.co_id === param.ids[i]);
                    phoneArr.push(queryVal.real_phone || 'false');
                }
                //绑定
                _this.props.bindAxb({
                    telA:phoneArr,
                    type:'receive',
                    flag:'private'
                }).then(res=>{
                    if(res.code === 0){
                        _this.setState({
                            sureIcon:false
                        })
                        //绑定成功后领取
                        this.props.getOrder(param,res=>{
                            if(res.code === 0){
                                _this.setState({
                                    iconLoading:false
                                });
                                message.success('领取成功');
                                _this.closeOrderWindow();
                                _this.setState({
                                    selectedRowKeys:[],
                                    // 关闭外呼弹屏（CallOpenWindow）
                                    showCallOpenWindow : false,
                                });

                            }else{
                                message.error(res.message);
                                this.setState({
                                    iconLoading:false,
                                    //关闭领取提示框
                                    showOrderWindow:false
                                })
                                //取消解绑
                                _this.props.nobindAxb({telA:phoneArr}).then();
                            }
                        })
                    }else{
                        _this.setState({
                            iconLoading:false,
                            sureIcon:false
                        });
                        message.error('绑定备用号码失败，请稍候再试。');
                    }
                })
            }else{
                message.warning(`当前备用号码仅剩${len}个`);
                _this.setState({
                    sureIcon:false
                })
            }
        });


    };

    render() {
        return (
            <Layout className="wrap">
                {/*左侧*/}
                <Left
                    collapsed={this.state.collapsed}
                    {...this.props}
                />
                {/*右侧*/}
                <Layout>
                    {/*头部*/}
                    <Top
                        collapsed={this.state.collapsed}
                        toggle={this.toggle}
                        {...this.props}
                    />
                    {/*主要内容*/}
                    <Contents />

                    {/*外呼弹窗*/}
                    <CallOpenWindow
                        visible={this.state.showCallOpenWindow}
                        handleCancel={this.handleCancel}
                        handleGrabBtn={this.handleGrabBtn}  //领取弹窗
                        menuTab={this.menuTab}
                        co_id={this.state.co_id}
                        comInfo={this.props.comInfo}
                        comFollow={this.props.comFollow}
                        transfers={this.props.transfers}
                        getOperateList={this.props.getOperateList}  // 获取操作列表接口
                        // recordMenu={this.state.recordMenu}
                        handleAddFollow={this.handleAddFollow} //添加跟进记录弹窗
                        getProvinceList={this.props.getProvinceList}
                        iconLoading={this.state.iconLoading}
                        getCityList={this.props.getCityList}
                        getCountyList={this.props.getCountyList}
                        saveCom={this.props.saveCom}
                        addfollow={this.props.addfollow}
                        // getOrderTransfer={this.props.getOrderTransfer}
                        // inlet={this.state.inlet}
                        // getCostomerList={this.getTargAndInval}
                        // onClose={this.ClosehandleInforBox}
                        loading={this.state.callOpenWindowLoading}
                        inlet={this.state.inlet}
                        invite={this.props.invite}
                        editCom={this.props.editCom}
                        getComInfo={this.props.getComInfo}
                    />

                    {/*添加跟进记录弹窗*/}
                    <AddFollow
                        visible={this.state.showAddFollow}
                        handleCancel={this.handleAddFollow}
                        closeCallWindow={this.handleCancel}
                        addfollow={this.props.addfollow}
                        comInfo={this.props.comInfo}
                        getComInfo={this.props.getComInfo}
                        getComFollow={this.props.getComFollow}
                    />

                    {/*领取弹窗*/}
                    <GetOrderWindow
                        title='提示'
                        visible={this.state.showOrderWindow}
                        closeOrderWindow={this.closeOrderWindow}
                        selectedRowKeys={this.state.selectedRowKeys}
                        sureOrder={this.sureOrder}
                        sureIcon={this.state.sureIcon}
                    />

                </Layout>
            </Layout>
        );
    }
}

function mapStateToProps(state){
    return{
        userInfo : state.getUserInfo,
        comInfo : state.getComInfo,
        comFollow : state.getComFollow,
        transfers: state.getOrderTransfer,
        operate:state.getComOperateList,
        invite:state.getInviteStaff
    }
}

export default connect(mapStateToProps,{
    doLogout,
    getUserInfo,
    checkToken,
    getComInfo,
    getComFollow,
    getOrderTransfer,
    getOperateList,
    getProvinceList,
    phoneNumber,
    bindAxb,
    saveCom,
    addfollow,
    getOrder,
    getCityList,
    getCountyList,
    nobindAxb,
    getComOperateList,
    getInviteStaff,
    editCom,

})(Index);
