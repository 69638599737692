import React from 'react'
import './CallopenWindow.scss'
import './CustomerDetail.scss'

import {Button, message, Modal, Select, Table, Spin, Icon} from 'antd'
import {callStatus, pageSize} from "../utils/config";
import {isFunction} from "../utils/common";
import Tab from "../components/Tab/Tab";
import AddForm from "../components/AddForm/AddForm";
import {removeSection} from "../utils/common"
import funDialing from 'funDialing'
import batchDialing from 'batchDialing'

class CallOpenWindow extends React.Component{
    componentWillReceiveProps(nextProps){
        if(this.props.visible && !nextProps.visible){
            batchDialing.reactCallClose();
        }
        if(this.props.co_id != nextProps.co_id){
            this.getOperateList(1,nextProps.co_id);
        }
    }
    state={
        dataSource : [],
        dataSources : [],
        page : 1,
        pages:1,
        loading : false,
        pagination: {
            defaultPageSize : pageSize
        },
        paginations: {
            defaultPageSize : pageSize
        },

    };

    tab = [
        {
            dataindex : 'follow',
            name : '跟进记录'
        },{
            dataindex : 'revise',
            name : '修改信息'
        },{
            dataindex : 'record',
            name : '操作记录'
        }
    ];
    columns = [
        {
            title : '时间',
            dataIndex : 'create_time'
        },{
            title : '操作人',
            dataIndex : 'uid_name'
        },{
            title : '类型',
            dataIndex : 'type_name'
        },{
            title : '内容',
            dataIndex : 'desc'
        }
    ];

    transfers = [
        {
            title:'序号',
            render:(text,record,index)=>(
                <span>{index+1}</span>
            )
        },{
            title:'人员',
            dataIndex:'add_name'
        },{
            title:'记录类型',
            dataIndex:'state_name'
        },{
            title:'时间',
            dataIndex:'add_time'
        }
    ];



    //延期/放弃
    handleBatch = (value) => {
        this.props.changeBatchValue('batchValues',value)
        switch (value){
            case 'cancel' :
                // this.props.handleBatchImport('handleBatchValue',value);
                // this.props.cancelOrder();
                //显示弹窗
                this.props.cancelOrderNew();
                break;
            case 'delay' :
                this.props.setdelayOrderNew();
                break;
        }
    };


    // 获取操作列表
    getOperateList = (page,coid) =>{
        const _this = this;
        // loading
        this.setState({loading : true});
        this.props.getOperateList({
            page : page ? page : this.state.page,
            page_size : 10,
            co_id : coid || this.props.co_id
        },res => {
            this.setState({loading : false});
            if(res.code === 0){
                // 重置请求页码和分页器当前页码
                if(page){
                    const pager = { ...this.state.pagination };
                    pager.current = page;
                    _this.setState({
                        page : page,
                        pagination: pager
                    })
                }
                const pagination = { ...this.state.pagination };
                pagination.total = res.data.all_count;
                _this.setState({
                    pagination,
                    dataSource : res.data.list
                })
            }
        })

    };
    // 点击分页
    handleTableChange = (pagination) =>{
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
            page : pagination.current
        });
        this.getOperateList(pagination.current);
    };

    //获取流转记录
    getTransfer=(page)=>{
        const _this = this;
        // loading
        this.setState({loading : true});
        const param ={
            page : page ? page : this.state.pages,
            page_size : 10,
            co_id : this.props.co_id
        }
        this.props.getOrderTransfer(param,res => {
            this.setState({loading : false});
            if(res.code === 0){
                // 重置请求页码和分页器当前页码
                if(page){
                    const pager = { ...this.state.paginations };
                    pager.current = page;
                    _this.setState({
                        page : page,
                        paginations: pager
                    })
                }
                const paginations = { ...this.state.paginations };
                paginations.total = res.data.all_count;
                _this.setState({
                    paginations,
                    dataSources : res.data.list
                })
            }
        })
    };

    // 点击分页
    handleTableChanges = (paginations) =>{
        const pager = { ...this.state.paginations };
        pager.current = paginations.current;
        this.setState({
            paginations: pager,
            page : paginations.current
        });
        this.getTransfer(paginations.current);
    };

    // 点击call图标点拨电话
    handleCallIcon = (val) => {
        const _this = this;
        this.isAutoDialing(function(){
            // 呼叫电话
            funDialing({
                notCall : function(status){
                    let desc = ''
                    callStatus.map(item => {
                        if(item['status'] === status){
                            desc = item['desc']
                        }
                    });
                    Modal.warning({
                        title  : desc,
                        okText : '确定'
                    })
                },
                phone : _this.props.comInfo.real_phone,
                co_id : _this.props.comInfo.co_id,
                type:val,
                flag:this.props.inlet,
                erorTips:function (message) {
                    //错误提示
                    Modal.error({
                        title:message,
                        okText:'确定'
                    })

                }
            })
        })
    };
    // 判断是否自动呼叫中
    isAutoDialing = (call) => {
        if(this.props.isAutoDialing){
            message.warn('正在自动呼叫中',1);
        }else{
            isFunction(call) && call();
        }
    };
    render(){
        const comInfo = this.props.comInfo;
        const Option = Select.Option;
        return(
            <Modal
                title={ this.props.comInfo.co_id && '客户ID' +  this.props.comInfo.co_id }
                visible={this.props.visible}
                onCancel={this.props.handleCancel}
                maskClosable={false}
                footer={null}
                width='80%'
                className="call-open-window reset-modal windowPadd"
                destroyOnClose={true}
            >

                {
                    this.props.loading ? <Spin/> :
                        <div className="customer-detail">
                                <div className='public-form-warp'>
                                    <ul>
                                        <li>
                                            <span className="li_title">客户ID:</span>
                                            <span>{comInfo.co_id}</span>
                                        </li>
                                        <li>
                                            <span className="li_title">客户来源:</span>
                                            <span>{comInfo.source_name}</span>
                                        </li>
                                        <li>
                                            <span className="li_title">添加时间:</span>
                                            <span>{comInfo.create_time}</span>
                                        </li>
                                        <li>
                                            <span className="li_title">客户类型:</span>
                                            <span>{comInfo.co_type_name}</span>
                                        </li>
                                    </ul>

                                    <AddForm overAll={true}  co_id={comInfo.co_id} action="edit" isEdit={true} {...this.props}/>
                                </div>


                            <div className="customer-detail-right">

                                <Tab menu={this.props.menuTab ? this.props.menuTab : this.tab} active={this.props.recordMenu ? this.props.recordMenu : null }>
                                    <div className=" new-customer-detail-con " dataindex="follow">
                                        <div className=' private-form-warp'>
                                            {
                                                this.props.comFollow.list ? this.props.comFollow.list.map((m) => {
                                                    return (
                                                        <ul  key={m.time} className="time-line">
                                                            <li >
                                                                <div key={m.create_time}>
                                                                    <span >{m.follow_name}<span className="time">{m.create_time}</span></span>
                                                                    {
                                                                        m.invalid_reason_desc !== '' && <span className="descName">{m.invalid_reason_desc}</span>

                                                                    }
                                                                    <span>{m.change_desc}</span>
                                                                    {
                                                                        m.tag_list.map(item=>{
                                                                            return (
                                                                                <span className='tagList'>{item}</span>
                                                                            )

                                                                        })
                                                                    }
                                                                    {
                                                                        m.call_state_desc &&
                                                                        m.call_state_desc !== '' && <span className="descName">{m.call_state_desc}</span>
                                                                    }
                                                                    <span>{m.desc}</span>
                                                                    <span>{m.communic_desc}</span>
                                                                    {
                                                                        m.voice_url &&
                                                                        <div className="sound-recording">
                                                                            <audio src={m.voice_url} controls="controls" onPlay={() => this.onPlayRecordCall(m.id)}></audio>
                                                                            <a href={m.voice_url}><Icon type="download" /></a>
                                                                        </div>
                                                                    }


                                                                </div>
                                                            </li>
                                                        </ul>
                                                    )
                                                }) : <div>暂时没跟进记录</div>
                                            }
                                        </div>

                                    </div>

                                    <div className="customer-detail-con private-form-warp" dataindex="transfer">
                                        <div >
                                            {

                                                this.props.operate  ? this.props.operate.map((m) => {
                                                    return (
                                                        <ul key={m.id} className="time-line">
                                                            {
                                                                <li >
                                                                    <div key={m.add_time}>
                                                                        <span className="name">
                                                                            <i className='addName'>{m.add_name}</i>
                                                                            <i className="time">{m.add_time}</i>
                                                                            <span>{m.type_name}</span>
                                                                        </span>
                                                                        <span>{m.content}</span>
                                                                    </div>
                                                                </li>
                                                            }
                                                        </ul>
                                                    )
                                                }) : <div>暂无客户流动情况</div>
                                            }
                                        </div>
                                        {/*<Table */}
                                        {/*columns={this.transfers}*/}
                                        {/*dataSource={this.props.transfers}*/}
                                        {/*pagination={this.state.paginations}*/}
                                        {/*rowKey={ (record,index) => (index).toString()}*/}
                                        {/*/>*/}
                                    </div>
                                </Tab>
                            </div>
                        </div>
                }


            </Modal>
        )
    }
}

export default CallOpenWindow