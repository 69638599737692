import React from 'react'
import {  Button } from 'antd'

class GetOrderDetail  extends React.Component{
    
    componentDidMount(){

    }   
    render(){
        return(
            <div className="update_warp">
                <p>
                    <span>
                        您将领取{ !!this.props.selectedRowKeys.length ? this.props.selectedRowKeys.length : 1}个客户到您的客户池中,领取后只有您(和上司)能更进这些客户,确认领取? 
                    </span>
                </p>
                <div>
                    <Button
                        type="ghost"
                        htmlType="button"
                        onClick={this.props.closeOrderWindow}
                    >取消</Button>
                    <Button
                        type="primary"
                        onClick={this.props.sureOrder}
                        loading={this.props.sureIcon}
                    >确定</Button>
                </div>
            </div>
        )
    }
}
export default GetOrderDetail