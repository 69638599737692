import React from 'react'
import {
    Form,
    Input,
    Radio,
    Button,
    Cascader,
    Select,
    message,
    DatePicker,
    Switch,
    Divider,
    Icon,
    Modal,
} from 'antd'
import { checkPhone } from "../../utils/regExp";
import {customerTypeSetUp, callStatus} from '../../utils/config'
import funDialing from 'funDialing'
import {getDate, isFunction} from "../../utils/common";
const Option = Select.Option
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18}
};
const formItemLayouts = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16}
};
class AddCustomerForm extends React.Component{
    state = {
        newCustomerType:'',
        cityOption : [],
        provinceList:[],
        cityList:[],
        loading : false,
        wxFlag:"1", //本机，非本机号码状态切换
        askQuestionType:'',
        //单选内容
        radioSec:[
            {
                type: 1,
                dec: '开场白挂机',
                checkoutStatus: false
            }, {
                type: 2,
                dec: '接通后挂机',
                // checkoutStatus:(this.props.comInfo.co_type ===0 || this.props.comInfo.co_type === 1  || this.props.comInfo.co_type === 2 ) ? true :false
                checkoutStatus: false
            }, {
                type: 3,
                dec: '接通后无应答',
                checkoutStatus: false
            }, {
                type: 4,
                dec: '通话中断',
                checkoutStatus: false
            }, {
                type: 5,
                dec: '晚点回电',
                checkoutStatus: false
            }, {
                type: 11,
                dec: '合作过但不想继续合作',
                // checkoutStatus:this.props.comInfo.co_type === 7 ? true :false
                checkoutStatus: false
            }, {
                type: 12,
                dec: '不考虑网单',
                checkoutStatus: false
            }, {
                type: 13,
                dec: '已和其他平台合作',
                checkoutStatus: false
            }, {
                type: 14,
                dec: '需求不合理',
                checkoutStatus: false
            }, {
                type: 99,
                dec: '其他',
                checkoutStatus: false
            }
        ],
        ask_question:[
            {type:'1',dec:'合作模式',checkoutStatus:false},
            {type:'2',dec:'收费标准',checkoutStatus:false},
            // {type:'3',dec:'有效定义',checkoutStatus: this.props.comInfo.co_type === 3 ? true :false},
            {type:'3',dec:'有效定义',checkoutStatus: false},
            {type:'4',dec:'订单质量',checkoutStatus:false},
            {type:'9',dec:'其他',checkoutStatus:false},
        ],
        communic:[
            {type:'1',dec:'电话联系',checkoutStatus:false},
            {type:'2',dec:'微信沟通',checkoutStatus:false},
            {type:'3',dec:'进店拜访',checkoutStatus:this.props.comInfo.co_type=== 3 ? true :false},
            {type:'3',dec:'进店拜访',checkoutStatus:false},
        ],
        isInvite:false,

    }

    newCustomerTypeStatus=(val)=>{
        //判断值是否存在
        // !! this.props.comInfo.co_type ? this.props.comInfo.co_type === 0 ? '1':this.props.comInfo.co_type === 6 ? '5'  :this.props.comInfo.co_type === 2 ? '1': this.props.comInfo.co_type.toString():'',
        if(!!val || val === 0){
            let sta =(val === 0 || val === 2 || val === 9 ) ? '1':val===6? '5':val.toString()
            this.setState({
                // 0 新客户
                newCustomerType:sta //设定客户类型初始值
            })
            this.changeUserType(sta)
        }
    }
    askQuestionStatus=(val)=>{
        //判断值是否为3
        if(!!val || val === 3){
            this.state.ask_question.map((item,index)=>{
                item.type === '3' ? item.checkoutStatus = !item.checkoutStatus:item.checkoutStatus = false;
                return ''
            })
        }
    }
    componentDidMount(){
        this.newCustomerTypeStatus(this.props.comInfo.co_type);
        // 是编辑的需要组合初始的cityOption
        if(this.props.isEdit){
            const comInfo = this.props.comInfo;
            //公有
            if(this.props.inlet==='public'){
                //获取省份
                this.getProvinceList({});
                // //获取城市
                this.getCityList(comInfo.province,{});
                // //获取区域
                this.getCountyList(comInfo.city,{});
            }
            else{
                this.getProvinceList(provinceArr=>{
                    this.getCityList(comInfo.province,cityArr=>{
                        this.getCountyList(comInfo.city,countyArr=>{
                            cityArr.map(item=>{
                                if(item.value === comInfo.city){
                                    item.children=countyArr
                                }
                                return ''
                            });
                            provinceArr.map(items=>{
                                if(items.value === comInfo.province){
                                    items.children=cityArr
                                }
                                return ''
                            })
                            this.setState({
                                cityOption:provinceArr
                            })
                        })
                    })
                });
            }
        }else{
            this.getProvinceList();
        }

    }

    //点击省重新获取省市
    provinceChange=(val)=>{
        //清空市区
        this.props.form.setFields({
            userCitys: {
                value: [],
            },
            userCityss:{
                value: [],
            }
        });
        //清空状态值
        this.setState({
            cityArr:[],
            countyArr:[]
        })
        //重新请求市
        this.getCityList(val,{})
    }

    //点击市重新获取区
    cityArrChange=(val)=>{
        //清空区
        this.props.form.setFields({
            userCityss:{
                value: [],
            }
        });
        //清空状态值
        this.setState({
            countyArr:[]
        })
        //重新获取一遍指派人信息
        this.props.getInviteStaff({co_id:this.props.comInfo.co_id,city_id:val});
        this.getCountyList(val,{})
    }


    // 获取省份
    getProvinceList = (call) =>{
        const _this = this;
        this.props.getProvinceList(function(res){
            if(res.code === 0){
                let provinceArr = []
                res.data.map(item => {
                    let province = {
                        value : item.id,
                        label : item.name,
                        isLeaf: false
                    }
                    provinceArr.push(province);
                    return ''
                })
                _this.setState({
                    cityOption : provinceArr
                });
                 isFunction(call) && call(provinceArr);
            }
        })
    }
    // 获取城市
    getCityList = (proid,call) =>{
        const _this = this;
        this.props.getCityList({province_id : proid},function(res){
            if(res.code === 0){
                let cityArr = []
                res.data.map(item => {
                    let city = {
                        value : item.id,
                        label : item.name,
                        isLeaf: false
                    }
                    cityArr.push(city);
                    return ''
                })
                 _this.setState({
                        cityArr
                    })
                isFunction(call) && call(cityArr)
            }
        })
    }
    // 获取区
    getCountyList = (cityid,call) => {
        const _this = this;
        this.props.getCountyList({city_id : cityid},function(res){
            if(res.code === 0){
                let countyArr = []
                res.data.map(item => {
                    let county = {
                        value : item.id,
                        label : item.name
                    }
                    countyArr.push(county);
                    return ''
                })
                 _this.setState({
                    countyArr
                }) ;
                isFunction(call) && call(countyArr);
            }
        })
    }
    loadData = (selectedOptions) => {
        const _this = this;
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;
        if(selectedOptions.length === 1){
            this.getCityList(targetOption.value,function (cityArr) {
                targetOption.loading = false;
                targetOption.children = cityArr;
                _this.setState({
                    cityOption : [..._this.state.cityOption],
                });
            })
        }else if(selectedOptions.length === 2){
            this.getCountyList(targetOption.value,function (countyArr) {
                targetOption.loading = false;
                targetOption.children = countyArr;
                _this.setState({
                    cityOption : [..._this.state.cityOption],
                });
            })
        }
    }


    //修改时
    refSub=(_this,values)=>{
        // 把客户信息提交到后台
        this.props.editCom(values).then(res=>{

            _this.setState({
                loading : false
            })
            if(res.code === 0){
                message.success(res.message,1);
                if(this.props.overAll){
                    //全局进来的 成功后刷新下自己
                    _this.props.getComInfo({co_id : values.co_id});

                }else{
                    _this.props.getComInfo({co_id : values.co_id});
                    //关闭弹窗
                    _this.props.handleCancel();
                    // 执行页面重加载
                    _this.props.getCostomerList(false,1,_this.props.keys);

                    // switch (_this.props.keys) {
                    //     case 'target':
                    //             _this.props.getCostomerList(false,1,'target')
                    //         break;
                    //     case 'Inval':
                    //         _this.props.getCostomerList(false,1,'inval')
                    //         break;
                    //     default:
                    //         _this.props.getCostomerList && _this.props.getCostomerList(false,1)
                    //         break;
                    // }
                    // 是弹层就关闭
                    // _this.props.modal &&
                    // _this.props.handleCancel();
                    // _this.props.onClose &&
                    // _this.props.onClose();
                }

            }else{
                message.error(res.message,1);
            }
        })
    }

    //添加
    refSub2=(_this,values)=>{
        // 把客户信息提交到后台
        this.props.addCom(values).then(res=>{

            _this.setState({
                loading : false
            })
            if(res.code === 0){
                message.success(res.message,1);
                _this.props.getComInfo({co_id : values.co_id});
                _this.props.handleCancel();
                // 执行页面重加载
                //由于新增的比较奇葩的需求，从综合查询中新增添加客户弹窗到公共客户中这个地方需要加个判断 从那个入口进来
                if(!!this.props.query){
                    this.props.getQueryList(false,1)

                }else{
                    _this.props.getCostomerList(false,1,_this.props.keys);
                }

            }else{
                message.error(res.message,1);
            }
        })
    }


    handleSubmits = (e) => {
        const _this = this;
        e.preventDefault();
            _this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.setState({
                        loading : true
                    })
                    // 通过this.props.action区分是新增还是编辑
                    let actions = this.props.action ? this.props.action : '';
                    values.co_id= this.props.co_id ? this.props.co_id : '';
                    // values.action = this.props.action ? this.props.action : '';   //2019-1-4接口已拆分 暂不用
                    values.inlet = this.props.inlet ? this.props.inlet:'';

                    //只有公有的编辑操作的地址不同（级联有改动,区域可不填）
                    if(this.props.inlet==='public' && this.props.action !=='add' ){
                        //整合数据，拆分省市区
                        values.province = values.userCity;
                        values.city = values.userCitys;
                        //判断区域是否有填
                        values.county = !!values.userCityss && '';
                        delete  values['userCity'];
                        delete  values['userCitys'];
                        delete  values['userCityss']
                    }else{
                        // 整合数据，拆分省市区
                        values.province = values.userCity[0];
                        values.city = values.userCity[1];
                        values.county = values.userCity[2];
                    }
                    //判断手机号码与座机号
                    if(!(values['phone'] || values['tel'])) {
                        message.error('手机号码与座机号至少填一项');
                        this.setState({
                            loading : false
                        })
                        return false;
                    }
                    //公共客户且不为新增时需要传跟进信息
                    if(this.props.inlet==='public' && this.props.action !=='add' ){
                        values['pre_time'] = values['pre_time'] ? getDate(values['pre_time']['_d']) : '';
                        values['is_invite'] = !!values['is_invite'] ? values['is_invite']:'';
                        values['co_tag'] = !!values['co_tag'] ? values['co_tag']:'';
                        values['co_id']=this.props.comInfo.co_id;
                        let askQuestion=[];
                        //寻找客户标签(不是无效类型情况下)
                        if(values['co_type']==='5'){
                            //寻找客户标签(是无效类型情况下)
                            this.invalid.map((item,index)=>{
                                if(item.checkoutStatus){
                                    values['co_tag']= item.type;
                                }
                            })
                        }else{
                            let tag ='';
                            this.state.radioSec.map((item,index)=>{
                                if(item.checkoutStatus){
                                    tag=item.type;
                                }
                            })
                            values['co_tag']=tag;
                        }
                        let inval ='';
                        //寻找无效标签
                        this.invalid.map((item,index)=>{
                            if(item.checkoutStatus){
                                inval = item.type
                            }
                        })
                        values['invalid_reason']=inval;
                        //寻找咨询问题
                        this.state.ask_question.map((item,index)=>{
                            if(item.checkoutStatus){
                                askQuestion.push(item.type);
                            }
                        })
                        values['ask_question'] = askQuestion;

                        //寻找沟通方式
                        this.state.communic.map((item,index)=>{
                            if(item.checkoutStatus){
                                values['communic']= item.type
                            }
                        })
                        //判断是否邀约
                        values['is_invite'] =values['is_invite'] === true ? "1":"0"
                    }

                    if(actions !=='add'){
                        values.has_serv_plat=values.has_serv_plat===0? '':values.has_serv_plat;
                        values.serv_range=values.serv_range===0? '':values.serv_range;
                    }
                    delete values.userCity;
                    let val = this.props.comInfo && this.props.comInfo.is_hide_phone === 1 ? delete values.phone : values
                    //判断为填写情况 (公有时需此判断)
                    //公有情况下的编辑
                   if(this.props.inlet==='public'  && actions !=='add'){
                       //无效且编辑时
                       if(this.noshow.indexOf(val['co_type']) !== -1){
                           //无效原因未有填写的
                           if(val['invalid_reason']=== ''){
                               message.error('无效原因不能为空');
                               _this.setState({
                                   loading : false
                               });
                               return false;
                           }else{
                               this.refSub(_this,val)
                           }
                       }else if(val['co_type'] === '3'){
                           //为意向时判断
                           this.refSub(_this,val)
                       } else{

                           if(val['co_tag']===''){
                               message.error('请选择客户标签类型');
                               _this.setState({
                                   loading : false
                               });
                               return false;
                           }else{
                               this.refSub(_this,val)
                           }
                       }
                   }else{
                    console.log(val)
                       actions !=='add' ?
                       this.refSub(_this,val) : this.refSub2(_this,val);
                   }
                    // this.props.saveCom(values,function (res) {
                    //     _this.setState({
                    //         loading : false
                    //     })
                    //     if(res.code === 0){
                    //         message.success(res.message,1);
                    //         _this.props.getComInfo({co_id : values.co_id});
                    //         // 执行页面重加载
                    //         _this.props.getCostomerList(false,1,_this.props.keys);
                    //         // switch (_this.props.keys) {
                    //         //     case 'target':
                    //         //             _this.props.getCostomerList(false,1,'target')
                    //         //         break;
                    //         //     case 'Inval':
                    //         //         _this.props.getCostomerList(false,1,'inval')
                    //         //         break;
                    //         //     default:
                    //         //         _this.props.getCostomerList && _this.props.getCostomerList(false,1)
                    //         //         break;
                    //         // }
                    //         // 是弹层就关闭
                    //         // _this.props.modal &&
                    //         // _this.props.handleCancel();
                    //         // _this.props.onClose &&
                    //         // _this.props.onClose();
                    //     }else{
                    //         message.error(res.message,1);
                    //     }
                    //
                    // })
                }
            });
        
    }
    //客户类型选项改变
    changeUserType=(val)=>{
        //选项改变时客户标签进行改变
        // switch (val) {
        //     case '1':
        //         this.state.radioSec.map((item,index)=>{
        //             item.type === 2 ? item.checkoutStatus = true:item.checkoutStatus =false;
        //         })
        //         break;
        //     case '7':
        //         this.state.radioSec.map((item,index)=>{
        //             item.type === 11 ? item.checkoutStatus = true:item.checkoutStatus =false;
        //         })
        //         break;
        //     case '3':
        //
        //         this.state.ask_question.map((item,index)=>{
        //             item.type === '3' ? item.checkoutStatus = true:item.checkoutStatus =false;
        //         })
        //         this.state.communic.map((item,index)=>{
        //             item.type === '3' ? item.checkoutStatus = true:item.checkoutStatus =false;
        //         })
        //         break;
        //     case '5':
        //         this.invalid.map((item,index)=>{
        //             item.type === 6 ? item.checkoutStatus = true:item.checkoutStatus =false;
        //         })
        //         break;
        //
        //     default :
        //         this.state.radioSec.map((item,index)=>{
        //             item.checkoutStatus = false;
        //         })
        //         this.state.ask_question.map((item,index)=>{
        //             item.checkoutStatus = false;
        //         })
        //         this.state.communic.map((item,index)=>{
        //             item.checkoutStatus = false;
        //         })
        //         break;
        // }

        //清空客户标签
        this.state.radioSec.map((item,index)=>{
              item.checkoutStatus = false;
        })
        this.state.ask_question.map((item,index)=>{
              item.checkoutStatus = false;
        })
        this.state.communic.map((item,index)=>{
              item.checkoutStatus = false;
        })

        this.setState({
            newCustomerType:val
        })
    };



    //单选框改变--接听的状态
    callStatusChange=(indexs,type,content)=>{

        content.map((item,index)=>{
            if(indexs === index){
                item.checkoutStatus = !item.checkoutStatus;
            }else{
                item.checkoutStatus = false;
            }
        });
        if(content[indexs].checkoutStatus === true){
            this.setState({
                callStatus: type
            })
        }else{
            this.setState({
                callStatus:''
            });
        }
    };

    //多选框
    askQuestion=(indexs,type,content)=>{
        content.map((item,index)=>{
            if(indexs === index){
                item.checkoutStatus= +item.checkoutStatus === 0 ? true:false
            }
        });

        if(content[indexs].checkoutStatus === true){
            this.setState({
                checkoutStatus: type
            })
        }else{
            this.setState({
                checkoutStatus:''
            });
        }
    }
    // 操作权限
    handleRank = () =>{
        const userRank = sessionStorage.getItem('userRank');
        return !userRank || userRank === "" || userRank === 'guest' || userRank === 'staff' || userRank === 'director' ? false : true
    }
    //负责人权限（组织架构中设置）
    organizRank =()=>{
        const organRole = sessionStorage.getItem('organRole');
        return organRole === 'leader' ? true : false
    };
    //不显示选框的条件
    noshow=["4","5","6"];
    //无效选择
    invalid = [
        {
            type:1,
            dec:'号码错误',
            checkoutStatus:false
        },
        {
            type:2,
            dec:'不是装修公司',
            checkoutStatus:false
        },
        {
            type:3,
            dec:'公司倒闭',
            checkoutStatus:false
        },
        {
            type:4,
            dec:'其他',
            checkoutStatus:false
        },{
            type:5,
            dec:'合作中',
            checkoutStatus:false
        },{
            type:6,
            dec:'家居/建材公司',
            checkoutStatus: this.props.comInfo.co_type === 6|| this.props.comInfo.co_type === 5 ? true:false
        },{
            type:7,
            dec:'同行登记',
            checkoutStatus:false
        }
    ];
    //微信号码是否显示后面的输入框
    wxFlag=(val)=>{
        this.setState({
            wxFlag:val
        })
    }
    //指派人
    isInvite=(val)=>{
        this.setState({
            isInvite:val
        })
    }
    //重置指派人
    onResetInvite=()=>{
        this.props.form.resetFields('invite_uid');
    }

    // 点击call图标点拨电话
    handleCallIcon = (val,phone) => {
        if(phone===''){
            message.warning('号码为空，不能呼叫！');
            return;
        }

        const _this = this;
        this.isAutoDialing(function(){
            // 呼叫电话
            funDialing({
                notCall : function(status){
                    let desc = ''
                    callStatus.map(item => {
                        if(item['status'] === status){
                            desc = item['desc']
                        }
                    });
                    Modal.warning({
                        title  : desc,
                        okText : '确定'
                    })
                },
                // _this.props.comInfo.phone
                // phone:_this.props.comInfo.real_phone,
                phone:phone.replace(/-/,''),
                // phone : _this.props.inlet === 'public' ? _this.props.comInfo.real_phone : !!_this.props.comInfo.sphone ? _this.props.comInfo.sphone : _this.props.comInfo.real_phone,
                co_id : _this.props.comInfo.co_id,
                type:val,
                flag:_this.props.inlet,
                erorTips:function (message) {
                    //错误提示
                    Modal.error({
                        title:message,
                        okText:'确定'
                    })

                }
            })
        })
    };

    // 判断是否自动呼叫中
    isAutoDialing = (call) => {
        if(this.props.isAutoDialing){
            message.warn('正在自动呼叫中',1);
        }else{
            isFunction(call) && call();
        }
    };




    render(){

        const { getFieldDecorator } = this.props.form;
        const writeBack = this.props.isEdit ? true : false;
        const inlet = this.props.inlet;
        const comInfo = this.props.comInfo;
        const RadioGroup = Radio.Group;
        const RadioButton = Radio.Button;
        const { TextArea } = Input;
        return(
            <div
                className={writeBack? 'public-BTN-warp':'formMarBto'} //common.scss
            >




                {
                    inlet ==='public' ?

                        //公有情况
                        <Form
                            layout={writeBack ? "inline" :'' }
                            onSubmit={this.handleSubmits}
                        >


                            {
                                writeBack ? <Divider orientation="left">基本信息</Divider> :''
                            }
                            {/*<div className='ant-modal-title new-modal-titel'>基本信息</div>*/}
                            {/*公司名称*/}


                                <Form.Item
                                    {...formItemLayouts}
                                    label="公司名称"
                                    style={{display:'flex'}}
                                >
                                    {getFieldDecorator('co_name', {
                                        initialValue :writeBack ?  comInfo.co_name :'',
                                        rules: [{ required: true, message: '请输入公司名称！' }],
                                    })(
                                        <Input
                                            maxLength="30" />
                                    )}
                                </Form.Item>
                            <div className='public-top marBtm'>
                                {/*客户姓名*/}
                                <Form.Item
                                    {...formItemLayouts}
                                    label="联系人"
                                >
                                    {getFieldDecorator('name', {
                                        initialValue : writeBack ?comInfo.name:''  ,
                                        rules: [{ required: true, message: '请输入联系人昵称！' }],
                                    })(
                                        <Input maxLength="15" />
                                    )}
                                </Form.Item>

                                {/*性别*/}
                                <Form.Item
                                    {...formItemLayouts}
                                    label="客户性别"
                                    className='testTag'
                                >
                                    {getFieldDecorator('sex',{
                                        initialValue :  writeBack ? comInfo.sex &&  comInfo.sex.toString() : '1' ,
                                    })(
                                        <Radio.Group onChange={this.onChangeSex}>
                                            <Radio value='1'>先生</Radio>
                                            <Radio value='2'>女士</Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                            </div>
                            <div className='marBtm'>
                                {/*联系电话*/}
                                {
                                    //有权限的账号可修改（还需添加输入限制），没有不可修改
                                    // comInfo &&  comInfo.is_hide_phone === 1 ?
                                    //换成组织架构负责人
                                    this.props.action === 'add'   ?

                                        <Form.Item
                                            {...formItemLayouts}
                                            label="手机号码"
                                        >

                                            {getFieldDecorator('phone', {
                                                // initialValue : writeBack ? this.props.inlet==='public' ?  comInfo.contacts:comInfo.sphone  : '' ,
                                                initialValue :writeBack ?  comInfo.phone:'' , //因界面变动，改为了手机跟座机
                                                rules: [
                                                    { pattern : checkPhone ,message: '只能输入数字和“-”' }
                                                ]
                                            })(

                                                <Input maxLength="15"  />
                                            )}
                                        </Form.Item> :
                                        this.organizRank() === false ?
                                            <Form.Item
                                                {...formItemLayouts}
                                                label="手机号码"
                                                className='NewPhone'
                                            >
                                                {getFieldDecorator('phone', {
                                                    // initialValue : writeBack ?  this.props.inlet==='public' ?  comInfo.contacts:comInfo.sphone  : '' ,
                                                    initialValue : writeBack ? comInfo.phone :'',
                                                })(

                                                     <Input disabled />

                                                )}
                                                <span className='phone-icon' onClick={()=>this.handleCallIcon('call',comInfo.phone)}>
                                                    <Icon type="phone" theme="filled" />
                                                </span>
                                            </Form.Item>

                                            :

                                            <Form.Item
                                                {...formItemLayouts}
                                                label="手机号码"
                                                className='NewPhone'
                                            >

                                                {getFieldDecorator('phone', {
                                                    // initialValue : writeBack ? this.props.inlet==='public' ?  comInfo.contacts:comInfo.sphone  : '' ,
                                                    initialValue : writeBack ? comInfo.phone:'',
                                                    rules: [
                                                        { pattern : checkPhone ,message: '只能输入数字和“-”' }
                                                    ]
                                                })(
                                                    <Input maxLength="15"  />
                                                )}

                                                <span className='phone-icon' onClick={()=>this.handleCallIcon('call',  comInfo.phone)}>
                                                    <Icon type="phone" theme="filled" />
                                                </span>



                                            </Form.Item>
                                }
                                {/*座机号码*/}
                                <Form.Item
                                    {...formItemLayouts}
                                    label="座机号码"
                                    className='NewPhone'
                                >

                                    {getFieldDecorator('tel', {
                                        // initialValue : writeBack ? this.props.inlet==='public' ?  comInfo.contacts:comInfo.sphone  : '' ,
                                        initialValue :writeBack ? comInfo.tel :'' ,
                                        rules: [
                                            { pattern : this.organizRank()=== false ?'':checkPhone ,message: this.organizRank()=== false ?'':'只能输入数字和“-”' }
                                        ]
                                    })(
                                        this.props.action === 'add'   ?  <Input   maxLength="15"  />:


                                                <Input disabled ={this.organizRank() === false ? true :false }  maxLength="15"  />

                                    )}
                                    {
                                        this.props.action === 'add'   ?
                                            '':
                                            <span className='phone-icon' onClick={()=>this.handleCallIcon('call',comInfo.tel)}>
                                                <Icon type="phone" theme="filled" />
                                            </span>
                                    }




                                </Form.Item>
                            </div>
                            <div className='marBtm'>
                                {/*电子邮箱*/}
                                <Form.Item
                                    {...formItemLayouts}
                                    label="电子邮箱"
                                    className='testTag marBtm'
                                >
                                    {getFieldDecorator('email', {
                                        initialValue : writeBack ? comInfo.email:'',
                                        rules: [{ type:'email', message: '请输入正确的电子邮箱！' }],
                                    })(
                                        <Input

                                            maxLength="30"
                                        />
                                    )}
                                </Form.Item>
                                {/*微信号码*/}
                                <Form.Item
                                    {...formItemLayouts}
                                    label="微信号码"
                                    className='testTag weixintel  '
                                >
                                    {getFieldDecorator('wx_flag',{
                                        initialValue :'1',
                                    })(

                                        <Select
                                            style={{ width: '26%',marginRight:10 }}
                                            onChange={this.wxFlag}
                                        >
                                            <Option value="1">本机号码</Option>
                                            <Option value="2">非本机</Option>
                                        </Select>
                                    )}


                                    {
                                        this.state.wxFlag === '1' ? '':
                                        getFieldDecorator('weixin',{
                                            initialValue : writeBack ? comInfo.weixin:''  ,
                                        })(

                                        <Input  maxLength="30" />
                                        )
                                    }

                                </Form.Item>
                            </div>

                            {
                                writeBack ?
                                    <div className='marBtm '>
                                        <div className='publicAddr marBtm'>
                                            {/*省*/}
                                            {/*城市*/}
                                            <Form.Item
                                                className='province'
                                                {...formItemLayouts}
                                                label="地址"
                                            >
                                                {getFieldDecorator('userCity', {
                                                    initialValue : writeBack? comInfo.province:'',
                                                    rules: [{ required: true, message: '请选择客户所在省份！' }],
                                                })(

                                                    <Select
                                                        style={{ width: 120 }}
                                                        onChange={this.provinceChange}
                                                    >

                                                    {
                                                        this.state.cityOption.length>0 && this.state.cityOption.map(item=>{
                                                            return(
                                                                <Option key={item.value} value={item.value}>{item.label}</Option>
                                                            )
                                                        })
                                                    }

                                                    </Select>

                                                )}
                                            </Form.Item>

                                            {/*市*/}
                                            <Form.Item
                                                className='province'
                                            >

                                                {getFieldDecorator('userCitys', {
                                                    initialValue : writeBack? comInfo.city:'',
                                                    rules: [{ required: true, message: '请选择客户所在城市！' }],
                                                })(

                                                    <Select
                                                        style={{ width: 200 }}
                                                        placeholder='请选择客户所在城市'
                                                        onChange={this.cityArrChange}
                                                    >
                                                        {
                                                            this.state.cityArr && this.state.cityArr.map(item=>{
                                                                return(
                                                                    <Option key={item.value} value={item.value}>{item.label}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>

                                                )}
                                            </Form.Item>
                                            {/*区*/}
                                            <Form.Item

                                                className='province'
                                            >
                                                {getFieldDecorator('userCityss', {
                                                    initialValue : writeBack? comInfo.county===0 ? [] : comInfo.county :'',
                                                })(

                                                    <Select
                                                        style={{ width:200 }}
                                                        placeholder='请选择客户所在区域'
                                                    >
                                                        {
                                                            this.state.countyArr && this.state.countyArr.map(item=>{
                                                                return(
                                                                    <Option key={item.value} value={item.value}>{item.label}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>

                                                )}
                                            </Form.Item>
                                        </div>

                                        <div>
                                            {/*详细地址*/}
                                            <Form.Item
                                                label="详细地址"
                                            >
                                                {getFieldDecorator('address',{
                                                    initialValue :writeBack ? comInfo.address:''  ,
                                                })(
                                                    <Input maxLength="100" />
                                                )}
                                            </Form.Item>
                                        </div>
                                    </div> :
                                    <div>
                                        {/*城市*/}
                                        <Form.Item
                                            {...formItemLayouts}
                                            label="所在城市"

                                        >
                                            {getFieldDecorator('userCity', {
                                                initialValue : writeBack? [comInfo.province,comInfo.city,comInfo.county] :'',
                                                rules: [{ required: true, message: '请选择客户所在城市！' }],
                                            })(
                                                <Cascader

                                                    options={this.state.cityOption}
                                                    onChange={this.onChange}
                                                    loadData={this.loadData}
                                                    placeholder="请选择客户所在城市"
                                                />
                                            )}
                                        </Form.Item>
                                        {/*详细地址*/}
                                        <Form.Item
                                            {...formItemLayouts}
                                            label="详细地址"
                                        >

                                            {getFieldDecorator('address',{
                                                initialValue :writeBack ? comInfo.address:''  ,
                                                rules: [{ required: true, message: '请输入客户详细地址！' }],
                                            })(
                                                <Input maxLength="100" />
                                            )}
                                        </Form.Item>
                                    </div>
                            }



                            {
                                // newCustomerType
                                writeBack ?
                                    <div className='servRange'>
                                        {/*业务范围*/}
                                        <Form.Item
                                            {...formItemLayouts}
                                            label="业务范围"
                                            className='testTag weixintel'
                                        >
                                            {
                                                this.state.newCustomerType==='3' ?
                                                getFieldDecorator('serv_range',{
                                                initialValue :  '1'  ,
                                                 rules: [{ required:  true, message: '请选择业务范围！' }]

                                                })(
                                                    <Radio.Group >
                                                        <Radio value='1'>全市</Radio>
                                                        <Radio value='2'>市区</Radio>
                                                    </Radio.Group>
                                                ) :
                                                    getFieldDecorator('serv_range',{
                                                        initialValue : comInfo.serv_range &&  comInfo.serv_range.toString() ,

                                                    })
                                                (
                                                    <Radio.Group >
                                                        <Radio value='1'>全市</Radio>
                                                        <Radio value='2'>市区</Radio>
                                                    </Radio.Group>
                                                )
                                            }
                                        </Form.Item>
                                        {/*有无合作范围*/}
                                        <Form.Item
                                            {...formItemLayouts}
                                            label="合作平台"
                                            className='testTag weixintel'
                                        >
                                            {
                                                //客户类型为意向时，合作平台跟业务范围是必填项
                                                this.state.newCustomerType==='3' ?
                                                    getFieldDecorator('has_serv_plat',{
                                                        initialValue : '1' ,
                                                        rules: [{ required:  true, message: '请选择合作平台！' }]
                                                    })(
                                                        <Radio.Group >
                                                            <Radio value='1'>有</Radio>
                                                            <Radio value='2'>无</Radio>
                                                        </Radio.Group>
                                                    ):
                                                    getFieldDecorator('has_serv_plat',{
                                                    initialValue : comInfo.has_serv_plat && comInfo.has_serv_plat.toString() ,
                                                    })(
                                                        <Radio.Group >
                                                            <Radio value='1'>有</Radio>
                                                            <Radio value='2'>无</Radio>
                                                        </Radio.Group>
                                                    )
                                            }
                                        </Form.Item>
                                    </div> :''
                            }

                            {/*跟进情况*/}
                            {
                                writeBack ?
                                    <Divider orientation="left" >跟进情况</Divider>:''
                            }
                            {
                                //co_type === 0 新客户按潜在客户算
                                writeBack ?
                                    <div className='follow-type'>

                                        <Form.Item
                                            label="客户类型"
                                            className='marBtm'
                                        >
                                            {getFieldDecorator('co_type',{
                                                initialValue :((comInfo.co_type === 0 || comInfo.co_type === 2 || comInfo.co_type === 9 )  ? '1':comInfo.co_type === 6 ? '5'  : comInfo.co_type && comInfo.co_type.toString()) ,
                                                rules: [{ required: true, message: '请选择客户类型！' }]
                                            })(

                                                <RadioGroup >
                                                    {
                                                        customerTypeSetUp.map(item => {
                                                            return   <RadioButton  key={item.id} value={item.id} onClick={ ()=>this.changeUserType(item.id)}>{item.name}</RadioButton>
                                                        })
                                                    }
                                                </RadioGroup>


                                                // <Select style={{ width: 278 }} onChange={ (val)=>this.changeUserType(val)}>
                                                //     {
                                                //         customerTypeSetUp.map(item => {
                                                //             return   <Option key={item.id} value={item.id}>{item.name}</Option>
                                                //         })
                                                //     }
                                                // </Select>
                                            )}
                                        </Form.Item>
                                        {/*客户标签*/}

                                    {
                                        this.noshow.indexOf(this.state.newCustomerType) === -1 ?  //是否显示客户标签
                                            <div className='rest_warp marBtm '>

                                                <span className="ant-col-3 ant-form-item-label ">
                                                    {
                                                        this.state.newCustomerType === '3'?
                                                            <label>咨询问题</label> :
                                                            <label className="ant-form-item-required">客户标签</label>
                                                    }

                                                </span>

                                                <span className="ant-col-20">
                                            {

                                                <div>
                                                    {/*<Form.Item*/}
                                                    {/*style={{margin:0}}*/}
                                                    {/*>*/}
                                                    {/*{getFieldDecorator('desc_tag',{*/}
                                                    {/*initialValue : '',*/}
                                                    {/*// rules: [{ required: true, message: '请输入跟进内容！' }]*/}
                                                    {/*})(*/}
                                                    {/*<div className='restRadioGroup'>*/}
                                                    {/*{*/}
                                                    {/*this.state.options.map((item,index)=>{*/}
                                                    {/*return (*/}
                                                    {/*<span*/}
                                                    {/*className={`${"checkStatus "}${item.checkoutStatus && "checkActive"}`}*/}
                                                    {/*key={item.value}*/}
                                                    {/*onClick={()=>this.callStatusChange(index,item.value,this.state.options)}*/}
                                                    {/*>*/}
                                                    {/*{item.label}*/}
                                                    {/*</span>*/}
                                                    {/*)*/}
                                                    {/*})*/}
                                                    {/*}*/}
                                                    {/*/!*<CheckboxGroup options={this.options}  onChange={this.followIngChange} />*!/*/}
                                                    {/*</div>*/}

                                                    {/*)}*/}
                                                    {/*</Form.Item>*/}
                                                    {
                                                        //咨询问题选择与客户标签切换  2018.12份最后一版的默认值--》 !!comInfo.ask_question ? comInfo.ask_question.toString():
                                                        this.state.newCustomerType === '3' ?
                                                            <Form.Item
                                                                style={{margin:0}}
                                                            >
                                                                {getFieldDecorator( 'ask_question',{
                                                                    initialValue : '',
                                                                })(
                                                                    <div >
                                                                        {
                                                                            this.state.ask_question.map((item,index)=>{
                                                                                return (

                                                                                    <span
                                                                                        className={`${"checkStatus "}${item.checkoutStatus && "checkActive"}`}
                                                                                        key={item.type}
                                                                                        onClick={()=>this.askQuestion(index,item.type,this.state.ask_question)} >

                                                                                        { item.dec}
                                                                                    </span>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>

                                                                )}
                                                            </Form.Item>:
                                                            <Form.Item
                                                                style={{margin:0}}

                                                            >



                                                                {getFieldDecorator( 'co_tag',{
                                                                    initialValue :'',

                                                                })(
                                                                    <div className='restRadioGroup'>
                                                                        {
                                                                            this.state.radioSec.map((item,index)=>{

                                                                                return (
                                                                                    (this.state.newCustomerType ==='1' || this.state.newCustomerType === '9')  && (item.type ===1 || item.type === 2 || item.type === 3 || item.type ===4 || item.type=== 99) ?

                                                                                        <span
                                                                                            className={`${"checkStatus "}${item.checkoutStatus && "checkActive"}`}
                                                                                            key={item.type}
                                                                                            onClick={()=>this.callStatusChange(index,item.type,this.state.radioSec)} >
                                                                                        {/*不同type展示的描述*/}
                                                                                            { item.dec}
                                                                                        </span>:
                                                                                    this.state.newCustomerType ==='7' && (item.type>=11  ) ?
                                                                                        <span
                                                                                            className={`${"checkStatus "}${item.checkoutStatus && "checkActive"}`}
                                                                                            key={item.type}
                                                                                            onClick={()=>this.callStatusChange(index,item.type,this.state.radioSec)} >
                                                                                            {/*不同type展示的描述*/}
                                                                                            { item.dec}
                                                                                        </span>:''
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>

                                                                )}
                                                            </Form.Item>
                                                    }

                                                </div>
                                            }
                                            </span>
                                            </div>:''
                                    }

                                    {/*沟通方式*/}
                                    {
                                        this.state.newCustomerType === '3' ?
                                            <div className='rest_warp marBtm'>

                                                <span className="ant-col-3 ant-form-item-label "><label className="ant-form-item-required">沟通方式</label></span>

                                                <span className="ant-col-20">
                                            {

                                                <div>
                                                    {/*2018.12最后一版 =》默认值 !!comInfo.ask_question ? comInfo.communic.toString():*/}
                                                    <Form.Item
                                                        style={{margin:0}}

                                                    >
                                                        {getFieldDecorator('communic',{
                                                            initialValue : '',
                                                        })(
                                                            <div className='restRadioGroup'>
                                                                {
                                                                    this.state.communic.map((item,index)=>{
                                                                        return (
                                                                            <span
                                                                                className={`${"checkStatus "}${item.checkoutStatus && "checkActive"}`}
                                                                                key={item.type}
                                                                                onClick={()=>this.callStatusChange(index,item.type,this.state.communic)} >
                                                                            { item.dec}
                                                                        </span>
                                                                        )
                                                                    })
                                                                }
                                                            </div>

                                                        )}
                                                    </Form.Item>
                                                </div>
                                            }
                                            </span>
                                            </div>:''
                                    }


                                    {/*无效类型*/}
                                    {/*<Form.Item*/}
                                    {/*label="无效原因"*/}
                                    {/*{...formItemLayout}*/}
                                    {/*>*/}
                                    {/*{getFieldDecorator('invalid_reason',{*/}
                                    {/*initialValue : '请选择',*/}
                                    {/*rules: [{ required: true, message: '请选择无效类型！' }]*/}
                                    {/*})(*/}
                                    {/*<Select  style={{ width: 278 }}>*/}
                                    {/*{*/}
                                    {/*this.invalid.map(item => {*/}
                                    {/*return <Option key={item.type} value={item.type}>{item.dec}</Option>*/}
                                    {/*})*/}
                                    {/*}*/}
                                    {/*</Select>*/}
                                    {/*)}*/}
                                    {/*</Form.Item>*/}
                                    {
                                        this.state.newCustomerType === "5" ?

                                            <div className='rest_warp marBtm'>

                                                <span className="ant-col-3 ant-form-item-label "><label className="ant-form-item-required">无效原因:</label></span>

                                                <span className="ant-col-20">
                                {

                                    <div>
                                        {/*  2018.12最后一版-》无效默认值  comInfo.invalid_reason */}

                                        <Form.Item
                                            style={{margin:0}}

                                        >
                                            {getFieldDecorator('invalid_reason',{
                                                initialValue : '',
                                            })(
                                                <div className='restRadioGroup'>
                                                    {

                                                        this.invalid.map((item,index)=>{
                                                            return (
                                                                item.type !==4 &&
                                                                <span
                                                                    className={`${"checkStatus "}${item.checkoutStatus && "checkActive"}`}
                                                                    key={item.type}
                                                                    onClick={()=>this.callStatusChange(index,item.type,this.invalid)} >
                                                                            { item.dec}
                                                                        </span>
                                                            )
                                                        })
                                                    }
                                                </div>

                                            )}
                                        </Form.Item>
                                    </div>
                                }
                                </span>
                                            </div>
                                            :''
                                    }
                                    {/*下次跟进时间*/}
                                    {
                                        this.state.newCustomerType === '1' || this.state.newCustomerType === '7' ?

                                            <Form.Item
                                                label="回访时间"
                                                {...formItemLayout}
                                                className='marBtm'
                                            >
                                                {getFieldDecorator('pre_time',{
                                                    initialValue : null,
                                                })(
                                                    <DatePicker
                                                        placeholder="选择时间"
                                                        style={{ width: 278 }}
                                                        format="YYYY-MM-DD HH:mm:ss"
                                                        showTime
                                                    />
                                                )}
                                            </Form.Item>:''
                                    }


                                    <Form.Item
                                        label="备注信息"
                                        {...formItemLayout}
                                        className="marBtm"
                                    >
                                        {getFieldDecorator('desc',{
                                            initialValue : comInfo.desc?comInfo.desc:'',

                                        })(
                                            <div className='restRadioGroup'>
                                            <TextArea
                                                autosize={{ minRows: 2, maxRows: 6 }}
                                            />
                                            </div>

                                        )}
                                    </Form.Item>
                                    <div className='inviteStyle' >
                                    {
                                        (comInfo.is_invite ===1 || this.state.newCustomerType === "3")?


                                            <Form.Item
                                                {...formItemLayout}
                                                label="是否邀约"
                                                className='marBtm'
                                            >
                                                {getFieldDecorator('is_invite', { valuePropName: 'checked' })(
                                                    <Switch onChange={this.isInvite} />
                                                )}
                                            </Form.Item>
                                            :''
                                    }


                                    {/*指派人*/}
                                    {
                                        this.state.isInvite  &&
                                        <Form.Item
                                            label="指派给"
                                            {...formItemLayout}
                                            className='marBtm'
                                        >
                                            {getFieldDecorator('invite_uid',{
                                                initialValue : !!this.props.invite ?  this.props.invite[0]['id']:'' ,
                                                rules: [{ required: true, message: '请选择指派人！' }]

                                            })(
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(inputValue, option) => option.props.children.indexOf(inputValue) !== -1}
                                                    style={{ width: 176 }}>
                                                    {
                                                        this.props.invite.map(item => {
                                                            return <Option key={item.id} value={item.id}>{item.name}</Option>
                                                        })
                                                    }
                                                </Select>
                                            )}
                                        </Form.Item>
                                    }

                                    {
                                        this.state.isInvite  && <Button className='marBtm circle-btn'  shape="circle" icon="reload"  onClick={this.onResetInvite} />
                                    }

                                    </div>

                                    {/*详细信息*/}
                                    {/*<ul className="infor-detail">*/}
                                    {/*<li>*/}
                                    {/*<span className="li_title">客户ID</span>*/}
                                    {/*<span>{comInfo.co_id}</span>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                    {/*<span className="li_title">公司</span>*/}
                                    {/*<span>{  comInfo.co_name}</span>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                    {/*<span className="li_title">联系电话</span>*/}

                                    {/*<span> { this.props.inlet === 'public'  ? comInfo.contacts : !! comInfo.sphone ?  comInfo.sphone : comInfo.contacts}</span>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                    {/*<span className="li_title">电子邮箱</span>*/}
                                    {/*<span>{comInfo.email}</span>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                    {/*<span className="li_title">微信</span>*/}
                                    {/*<span>{comInfo.weixin}</span>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                    {/*<span className="li_title">地址</span>*/}
                                    {/*<span>{`${comInfo.province_name}${comInfo.city_name}${comInfo.county_name}${comInfo.address}`}</span>*/}
                                    {/*</li>*/}
                                    {/*</ul>*/}
                                    {/*<ul className="infor-detail">*/}
                                    {/*<li>*/}
                                    {/*<span className="li_title">预约回访</span>*/}
                                    {/*<span className="li_time">{comInfo.pre_time}</span>*/}
                                    {/*</li>*/}
                                    {/*</ul>*/}
                                </div> :''
                            }


                            <div className={writeBack ? 'public-BTN' :'center'}   >

                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={this.state.loading}
                                    style={{width : '100px'}}
                                >
                                    {
                                        writeBack ? '保存记录':'确认新建'
                                    }

                                </Button>
                            </div>
                        </Form>
                        :
                        //私有情况
                        <Form   onSubmit={this.handleSubmits}>
                            <div className='private-form-warp'>
                                {/*公司名称*/}
                                <Form.Item
                                    {...formItemLayout}
                                    label="公司名称"
                                >
                                    {getFieldDecorator('co_name', {
                                        initialValue : writeBack ? comInfo.co_name : '' ,
                                        rules: [{ required: true, message: '请输入公司名称！' }],
                                    })(
                                        <Input
                                            maxLength="30" />
                                    )}
                                </Form.Item>

                                {/*城市*/}
                                <Form.Item
                                    {...formItemLayout}
                                    label="所在城市"
                                >
                                    {getFieldDecorator('userCity', {
                                        initialValue : writeBack ? [comInfo.province,comInfo.city,comInfo.county] : '' ,
                                        rules: [{ required: true, message: '请选择客户所在城市！' }],
                                    })(
                                        <Cascader
                                            options={this.state.cityOption}
                                            onChange={this.onChange}
                                            loadData={this.loadData}
                                            placeholder="请选择客户所在城市"
                                        />
                                    )}
                                </Form.Item>

                                {/*详细地址*/}
                                <Form.Item
                                    {...formItemLayout}
                                    label="详细地址"
                                >
                                    {getFieldDecorator('address',{
                                        initialValue : writeBack ? comInfo.address : '' ,
                                        rules: [{ required: true, message: '请输入客户详细地址！' }],
                                    })(
                                        <Input maxLength="100" />
                                    )}
                                </Form.Item>


                                {
                                    writeBack ?

                                        <Form.Item
                                            {...formItemLayout}
                                            label="业务范围"
                                            className='testTag'
                                        >
                                            {
                                                this.state.newCustomerType==='3' ?
                                                    getFieldDecorator('serv_range',{
                                                        initialValue :comInfo.serv_range && comInfo.serv_range.toString(),
                                                        rules: [{ required: true, message: '请输入业务范围！' }],
                                                    })(
                                                        <Radio.Group >
                                                            <Radio value='1'>全市</Radio>
                                                            <Radio value='2'>市区</Radio>
                                                        </Radio.Group>
                                                    )
                                                    :
                                                    getFieldDecorator('serv_range',{
                                                    initialValue : comInfo.serv_range &&  comInfo.serv_range.toString() ,
                                                    })(
                                                        <Radio.Group >
                                                            <Radio value='1'>全市</Radio>
                                                            <Radio value='2'>市区</Radio>
                                                        </Radio.Group>
                                                    )
                                            }
                                        </Form.Item> :''
                                }
                                {/*有无合作范围*/}
                                {
                                    writeBack ?
                                    <Form.Item
                                        {...formItemLayout}
                                        label="合作平台"
                                        className='testTag'
                                    >
                                        {
                                            this.state.newCustomerType==='3' ?
                                                getFieldDecorator('has_serv_plat',{
                                                    initialValue :comInfo.has_serv_plat && comInfo.has_serv_plat.toString()  ,
                                                    rules: [{ required: true, message: '请输入合作平台！' }],
                                                })(
                                                    <Radio.Group >
                                                        <Radio value='1'>有</Radio>
                                                        <Radio value='2'>无</Radio>
                                                    </Radio.Group>
                                                ):
                                                getFieldDecorator('has_serv_plat',{
                                                initialValue :comInfo.has_serv_plat && comInfo.has_serv_plat.toString() ,
                                                })(
                                                    <Radio.Group >
                                                        <Radio value='1'>有</Radio>
                                                        <Radio value='2'>无</Radio>
                                                    </Radio.Group>
                                                )
                                        }
                                    </Form.Item>:''
                                }
                                {/*客户姓名*/}
                                <Form.Item
                                    {...formItemLayout}
                                    label="联系人"
                                >
                                    {getFieldDecorator('name', {
                                        initialValue : writeBack ? comInfo.name : '' ,
                                        rules: [{ required: true, message: '请输入联系人昵称！' }],
                                    })(
                                        <Input maxLength="15" />
                                    )}
                                </Form.Item>

                                {/*性别*/}
                                <Form.Item
                                    {...formItemLayout}
                                    label="客户性别"
                                    className='testTag'
                                >
                                    {getFieldDecorator('sex',{
                                        initialValue : writeBack ? comInfo.sex &&  comInfo.sex.toString() : '1' ,
                                    })(
                                        <Radio.Group onChange={this.onChangeSex}>
                                            <Radio value='1'>先生</Radio>
                                            <Radio value='2'>女士</Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                                {/*联系电话*/}





                                {
                                    //有权限的账号可修改（还需添加输入限制），没有不可修改
                                    // comInfo &&  comInfo.is_hide_phone === 1 ?
                                    //换成组织架构负责人
                                    this.props.action === 'add'   ?
                                        <Form.Item
                                            {...formItemLayout}
                                            label="手机号码"
                                        >

                                            {getFieldDecorator('phone', {
                                                // initialValue : writeBack ? this.props.inlet==='public' ?  comInfo.contacts:comInfo.sphone  : '' ,
                                                initialValue : writeBack ? comInfo.phone :'', //因界面变动，改为了手机跟座机
                                                rules: [
                                                    { pattern : checkPhone ,message: '只能输入数字和“-”' }
                                                ]
                                            })(

                                                    <Input maxLength="15"  />


                                            )}
                                        </Form.Item> :
                                        this.organizRank() === false ?
                                            <Form.Item
                                                {...formItemLayout}
                                                label="手机号码"
                                            >
                                                {getFieldDecorator('phone', {
                                                    // initialValue : writeBack ?  this.props.inlet==='public' ?  comInfo.contacts:comInfo.sphone  : '' ,
                                                    initialValue : writeBack ? comInfo.real_phone :'',
                                                })(
                                                    <Input disabled />
                                                )}
                                            </Form.Item>
                                             :

                                            <Form.Item
                                                {...formItemLayout}
                                                label="手机号码"
                                            >
                                                {getFieldDecorator('phone', {
                                                    // initialValue : writeBack ? this.props.inlet==='public' ?  comInfo.contacts:comInfo.sphone  : '' ,
                                                    initialValue : writeBack ? comInfo.real_phone :'',
                                                    rules: [
                                                        { pattern : checkPhone ,message: '只能输入数字和“-”' }
                                                    ]
                                                })(
                                                        <Input maxLength="15"  />
                                                )}
                                            </Form.Item>
                                }




                                {/*座机号码*/}
                                {
                                    this.props.action === 'add' ?
                                        <Form.Item
                                            {...formItemLayout}
                                            label="座机号码"
                                        >
                                            {

                                                getFieldDecorator('tel', {
                                                    // initialValue : writeBack ? this.props.inlet==='public' ?  comInfo.contacts:comInfo.sphone  : '' ,
                                                    initialValue : writeBack ? comInfo.tel :'',
                                                    rules: [
                                                        { pattern : checkPhone ,message: '只能输入数字和“-”' }
                                                    ]
                                                })(
                                                   <Input  maxLength="15" />
                                                )
                                            }
                                        </Form.Item> :
                                        this.organizRank() === false ?
                                            <Form.Item
                                                {...formItemLayout}
                                                label="座机号码"
                                            >
                                                {

                                                    getFieldDecorator('tel', {
                                                        // initialValue : writeBack ? this.props.inlet==='public' ?  comInfo.contacts:comInfo.sphone  : '' ,
                                                        initialValue : writeBack ? comInfo.tel :'',
                                                        rules: [
                                                            { pattern : checkPhone ,message: '只能输入数字和“-”' }
                                                        ]
                                                    })(
                                                        <Input disabled />
                                                    )
                                                }
                                            </Form.Item>:
                                            <Form.Item
                                                {...formItemLayout}
                                                label="座机号码"
                                            >
                                                {

                                                    getFieldDecorator('tel', {
                                                        // initialValue : writeBack ? this.props.inlet==='public' ?  comInfo.contacts:comInfo.sphone  : '' ,
                                                        initialValue : writeBack ? comInfo.tel :'',
                                                        rules: [
                                                            { pattern : checkPhone ,message: '只能输入数字和“-”' }
                                                        ]
                                                    })(
                                                        <Input maxLength="15" />
                                                    )
                                                }
                                            </Form.Item>


                                }

                                {/*<Form.Item*/}
                                    {/*{...formItemLayout}*/}
                                    {/*label="座机号码"*/}
                                {/*>*/}
                                    {/*{*/}

                                        {/*getFieldDecorator('tel', {*/}
                                        {/*// initialValue : writeBack ? this.props.inlet==='public' ?  comInfo.contacts:comInfo.sphone  : '' ,*/}
                                            {/*initialValue : writeBack ? comInfo.tel :'',*/}
                                            {/*rules: [*/}
                                                {/*{ pattern : checkPhone ,message: '只能输入数字和“-”' }*/}
                                            {/*]*/}
                                        {/*})(*/}
                                            {/*this.organizRank() === false  ?<Input disabled />: this.props.action === 'add' ? <Input maxLength="15" /> :<Input maxLength="15" />*/}
                                        {/*)*/}
                                    {/*}*/}
                                {/*</Form.Item>*/}



                                {/*微信号码*/}
                                <Form.Item
                                    {...formItemLayout}
                                    label="微信号码"
                                    className='testTag weixintel'
                                >
                                    {getFieldDecorator('wx_flag',{
                                        initialValue :'1',
                                    })(

                                        <Select
                                            style={{ marginRight:10 }}
                                            onChange={this.wxFlag}
                                        >
                                            <Option value="1">本机号码</Option>
                                            <Option value="2">非本机</Option>
                                        </Select>
                                    )}
                                    {
                                        this.state.wxFlag === '1' ? '':
                                        getFieldDecorator('weixin',{
                                        initialValue : writeBack ? comInfo.weixin : '' ,
                                        })(

                                            <Input  maxLength="30" />
                                        )
                                    }
                                </Form.Item>

                                {/*电子邮箱*/}
                                <Form.Item
                                    {...formItemLayout}
                                    label="电子邮箱"
                                    className='testTag'
                                >
                                    {getFieldDecorator('email', {
                                        initialValue : writeBack ? comInfo.email : '' ,
                                        rules: [{ type:'email', message: '请输入正确的电子邮箱！' }],
                                    })(
                                        <Input maxLength="30" />
                                    )}
                                </Form.Item>

                                {/*客户类型 修改客户信息没有这个客户类型*/}
                                {/*{*/}
                                {/*this.props.action === 'add' && (*/}
                                {/*<Form.Item*/}
                                {/*label="客户类型"*/}
                                {/*>*/}
                                {/*{getFieldDecorator('co_type',{*/}
                                {/*initialValue : writeBack ? comInfo.co_type_name : customerType[1]['id'],*/}
                                {/*rules: [{ required: true, message: '请选择客户类型！' }]*/}
                                {/*})(*/}
                                {/*<Select style={{ width: 108 }}>*/}
                                {/*{*/}
                                {/*customerType.map(item => {*/}
                                {/*return item.id==='-1'? '':item.id==='4'?'':<Option key={item.id} value={item.id}>{item.name}</Option>*/}
                                {/*})*/}
                                {/*}*/}
                                {/*</Select>*/}
                                {/*)}*/}
                                {/*</Form.Item>*/}
                                {/*)*/}
                                {/*}*/}


                                {/*详细地址*/}
                                {/*<Form.Item*/}
                                {/*{...formItemLayout}*/}
                                {/*label="详细地址"*/}
                                {/*>*/}
                                {/*{getFieldDecorator('address',{*/}
                                {/*initialValue : writeBack ? comInfo.address : '' ,*/}
                                {/*rules: [{ required: true, message: '请输入客户详细地址！' }],*/}
                                {/*})(*/}
                                {/*<Input maxLength="100" />*/}
                                {/*)}*/}
                                {/*</Form.Item>*/}
                                {/*微信*/}
                                {/*<Form.Item*/}
                                {/*{...formItemLayout}*/}
                                {/*label="微信"*/}
                                {/*>*/}
                                {/*{getFieldDecorator('weixin',{*/}
                                {/*initialValue : writeBack ? comInfo.weixin : '' ,*/}
                                {/*})(*/}
                                {/*<Input maxLength="30" />*/}
                                {/*)}*/}
                                {/*</Form.Item>*/}
                                {/*电子邮箱*/}
                                {/*<Form.Item*/}
                                {/*{...formItemLayout}*/}
                                {/*label="电子邮箱"*/}
                                {/*>*/}
                                {/*{getFieldDecorator('email', {*/}
                                {/*initialValue : writeBack ? comInfo.email : '' ,*/}
                                {/*rules: [{ type:'email', message: '请输入正确的电子邮箱！' }],*/}
                                {/*})(*/}
                                {/*<Input maxLength="30" />*/}
                                {/*)}*/}
                                {/*</Form.Item>*/}
                            </div>
                            <div  className={writeBack ? 'default' :'center'}>


                                    {


                                        // this.props.can_grab_num === 0 ?
                                        // <Popconfirm
                                        //     title={'您的客户数量已达上限,是否将该客户添加进入公共客户中'}
                                        //     onConfirm={this.handleSubmit}
                                        // >
                                        //     <Button
                                        //         type="primary"
                                        //         htmlType="submit"
                                        //         loading={this.state.loading}
                                        //         style={{width : '100px'}}
                                        //     >
                                        //         提交
                                        //     </Button>
                                        // // </Popconfirm> :
                                        // //

                                        !!this.props.isBtnShow  ?
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={this.state.loading}
                                            style={{width : '100px'}}
                                        >
                                            {
                                                this.props.action === 'add'   ? '确认添加':'保存修改'
                                            }


                                        </Button>:''


                                    }
                            </div>
                        </Form>
                }
            </div>


        )
    }
}

const AddForm = Form.create()(AddCustomerForm);

export default AddForm