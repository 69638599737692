// 客户种类
export const customerType = [
    {
        id : '-1',
        name : '客户类型'
    },
    {
        id : '0',
        name : '新客户'
    },
    {
        id : '1',
        name : '潜在'
    },
    {
        id : '7',
        name : '暂无意向'
    },{
        id : '3',
        name : '意向'
    },
    {
        id : '8',
        name : '待签约'
    },{
        id : '5',
        name : '无效'
    }
];
//客户来源
export const customerSource = [
    {
        id : '-1',
        name : '客户来源'
    },{
        id : '0',
        name : '新客户'
    },{
        id : '1',
        name : '初步接触'
    },{
        id : '2',
        name : '持续跟进'
    },{
        id : '3',
        name : '线下跟进'
    }
]
// 跟进客户种类
export const followCustomerType = [
    {
        id : '-1',
        name : '全部客户'
    },{
        id : '1',
        name : '初步接触'
    },{
        id : '2',
        name : '持续跟进'
    },{
        id : '3',
        name : '线下跟进'
    }
]
// 全部客户种类
export const allCustomerType = [
    {
        id : '-1',
        name : '全部客户'
    },
    {
        id : '0',
        name : '新客户'
    },
    {
        id : '1',
        name : '潜在'
    },
    {
        id : '7',
        name : '暂无意向'
    },{
        id : '3',
        name : '意向'
    },
    {
        id : '8',
        name : '待签约'
    },{
        id : '5',
        name : '无效'
    }
];
//客户来源筛选
export const customerSouce =[
    {
        id:'-1',
        name:'客户来源'
    },
    {
        id:'1',
        name:'创建'
    },{
        id:'2',
        name:'导入'
    },{
        id:'4',
        name:'商务合作(PC)'
    },{
        id:'5',
        name:'商务合作(H5)'
    }
];
//关键字筛选条件
export const keyWordType=[
    {
        id:'1',
        name:'客户ID'
    },
    {
        id:'2',
        name:'客户姓名'
    },
    {
        id:'3',
        name:'手机号码'
    },
    {
        id:'4',
        name:'公司名称'
    }
];
//关键字筛选条件2
export const keyWordTypeTwo=[
    {
        id:'1',
        name:'公司ID'
    },
    {
        id:'2',
        name:'客户ID'
    },
    {
        id:'3',
        name:'公司名称'
    },
    {
        id:'4',
        name:'联系人'
    }
];


export const customerTypeSetUp=[
    // {id : '0',name : '新客户'},
    {id : '1',name : '潜在'},
    {id : '7',name : '暂无意向'},
    {id : '3',name : '意向'},
    {id : '5',name : '无效'}
];
//新的客户类型
export const coTypeSetUp=[
    { id:'0',name:'新客户'},
    { id:'1',name:'潜在'},
    { id:'3',name:'意向'},
    { id:'5',name:'无效'},
    { id:'7',name:'暂无意向'},
    { id:'8',name:'待签约'},
    { id:'4',name:'成交客户'}
]
// 选择时间类型
export const timeType = [
    {
        id:'0',
        name:'时间类型'
    },{
        id : '2',
        name : '跟进时间'
    },{
        id : '3',
        name : '预约时间'
    },{
        id:'4',
        name:'成交时间'
    },
    {
        id : '5',
        name : '领取时间'
    }
];
//综合查询时间类型

export const timeTypes = [
    {
        id:'',
        name:'时间类型'
    },{
        id : '1',
        name : '添加时间'
    },{
        id : '2',
        name : '领取时间'
    },{
        id:'3',
        name:'指派时间'
    },
    {
        id : '4',
        name : '成交时间'
    }
];
//公共客户选中时间类型
export const commType=[
    {
        id : '1',
        name : '添加时间'
    },{
        id : '2',
        name : '跟进时间'
    }
];

// 选择时间类型
export const validType = [
    {
        id : '1',
        name : '添加时间'
    }
];

//预警状态
export const warnType =[
    {
        id:'1',
        name:'正常',
    },
    {
        id:'2',
        name:'跟进预警'
    },{
        id:'3',
        name:"成交预警"
    },{
        id:'4',
        name:"延期中"
    },{
        id:'-1',
        name:"预警状态"
    }
]

// 审批状态
export const auditType = [
    {
        id : '0',
        name : '审批状态'
    },
    {
        id : '4',
        name : '已撤回'
    },
    {
        id : '3',
        name : '已驳回'
    },
    {
        id : '1',
        name : '审批中'
    },
    {
        id : '5',
        name : '未发起'
    }
]

// 默认列表每页的数据数
export const pageSize = 15;
//新的列表每页数据数(维护中心)
export const pageTotal = 15;
//数据统计
export const pageDataCount=20;

export const pageDetail=5;

// 电话条状态
export const callStatus = [
    {
        status : 0,
        desc : '未登录'
    },
    {
        status : 1,
        desc : '忙碌'
    },
    {
        status : 2,
        desc : '空闲'
    },
    {
        status : 3,
        desc : '通话中'
    },
    {
        status : 4,
        desc : '后续态'
    }
]

//订单类型
export const orderType = [
    {
        type:0,
        desc:'全部'
    },
    {
        type:1,
        desc:'可分'
    },
    {
        type:1,
        desc:'追加'
    },
    {
        type:1,
        desc:'不可分'
    },
]

//日期选择(有效)
export const vaildType=[
    {
        type:0,
        desc:'有效日期'
    },
    {
        type:1,
        desc:'分单日期'
    },
    {
        type:2,
        desc:'登记日期'
    }
]
//订单ID
export const orderId=[
    {
        type:0,
        desc:'按订单ID'
    },
    {
        type:1,
        desc:'按业主称呼'
    },
    {
        type:2,
        desc:'按发单手机'
    },
    {
        type:3,
        desc:'按公司ID'
    },
    {
        type:4,
        desc:'小区名称'
    },
    {
        type:5,
        desc:'装修公司名称'
    },
    {
        type:6,
        desc:'订单ID'
    }
]
//回复
export const reply=[
    {
        type:0,
        desc:'全部'
    },
    {
        type:1,
        desc:'已回复'
    },
    {
        type:2,
        desc:'未回复'
    }
]
//反馈状态
export const feedstatus=[
    {
        status:0,
        desc:'全部'
    },
    {
        status:1,
        desc:'已关闭'
    },
    {
        status:2,
        desc:'未关闭'
    }
]
//续费管理
export const paystatus=[
    {
        status:0,
        desc:'审批进度'
    },
    {
        status:1,
        desc:'审批中'
    },
    {
        status:2,
        desc:'已驳回'
    },
    {
        status:3,
        desc:'已通过'
    }
]
//人员配置
export const person =[
    {
        status:0,
        desc:'雇员选择'
    }

]

// 会员等级
export const memberGrade= [
    {
        type:1,
        desc:'非会员'
    },{
        type:2,
        desc:'V1(高级会员)'
    },{
        type:3,
        desc:'初级会员'
    },{
        type:5,
        desc:'延期会员'
    },{
        type:6,
        desc:'试用会员'
    },{
        type:10,
        desc:'V2(钻石会员)'
    },{
        type:11,
        desc:'V3(皇冠会员)'
    },{
        type:12,
        desc:'卖单会员'
    },{
        type:13,
        desc:'V4'
    },{
        type:14,
        desc:'V5'
    },{
        type:15,
        desc:'V6'
    },{
        type:16,
        desc:'V7'
    },{
        type:17,
        desc:'V8'
    },{
        type:18,
        desc:'V9'
    },{
        type:19,
        desc:'V10'
    }
]


// 接单范围
export const orderArea = [
    {
        value : 1,
        name : '< 60㎡'
    },{
        value : 2,
        name : '60㎡ ≤ X < 90㎡'
    },{
        value : 3,
        name : '90㎡ ≤ X < 120㎡'
    },{
        value : 4,
        name : '120㎡ ≤ X < 160㎡'
    },{
        value : 5,
        name : '≥ 160㎡'
    },{
        value : 6,
        name : '局改'
    },{
        value : 7,
        name : '工装'
    }
]

// 合同版本
export const contractVersion = [
    {
        value : '1',
        name : '卖单会员'
    },
    {
        value : '3',
        name : '新卖单会员'
    }
]

// 新卖单会员等级
export const vipLevel = [
    {
        name:'vip1',
        value:'1'
    },
    {
        name:'vip2',
        value:'2'
    },
    {
        name:'vip3',
        value:'3'
    },
    {
        name:'vip4',
        value:'4'
    },
    {
        name:'vip5',
        value:'5'
    },
    {
        name:'vip6',
        value:'6'
    },
    {
        name:'vip7',
        value:'7'
    },
    {
        name:'vip8',
        value:'8'
    },
    {
        name:'vip9',
        value:'9'
    }
]


export const monthTimes = [
    {
        name : '3个月',
        value : '3'
    },
    {
        name : '4个月',
        value : '4'
    },
    {
        name : '5个月',
        value : '5'
    },
    {
        name : '6个月',
        value : '6'
    },
    {
        name : '7个月',
        value : '7'
    },
    {
        name : '8个月',
        value : '8'
    },
    {
        name : '9个月',
        value : '9'
    },
    {
        name : '10个月',
        value : '10'
    },
    {
        name : '11个月',
        value : '11'
    },
    {
        name : '12个月',
        value : '12'
    },
    {
        name : '13个月',
        value : '13'
    },
    {
        name : '14个月',
        value : '14'
    },
    {
        name : '15个月',
        value : '15'
    },
    {
        name : '16个月',
        value : '16'
    },
    {
        name : '17个月',
        value : '17'
    },
    {
        name : '18个月',
        value : '18'
    },
    {
        name : '19个月',
        value : '19'
    },
    {
        name : '20个月',
        value : '20'
    },
    {
        name : '21个月',
        value : '21'
    },
    {
        name : '22个月',
        value : '22'
    },
    {
        name : '23个月',
        value : '23'
    },
    {
        name : '24个月',
        value : '24'
    }
]