import React from 'react'
import './Tab.scss'
import {isArray} from "../../utils/common";

/*********************************************************************
 * props
 * active： 默认选中第几个菜单，默认值为0
 * menu：tab菜单集合，属性为数组
 * cur：选中菜单的类名
 * children：tab的内容，需要一个容器包裹，有几个菜单就相对应有几个内容
 ********************************************************************/
class Tab extends React.Component{
    state = {
        selectMenu : ''
    }
    componentDidMount(){
        this.setState({
            selectMenu : this.props.active ? ( this.props.active === "" ? this.props.menu[0].dataindex : this.props.active ) : this.props.menu[0]['dataindex']
        })
    }
    handleClickTab = (dataindex) => {
        this.setState({
            selectMenu : dataindex
        })
    }
    render(){
        const cur = this.props.cur ? this.props.cur : 'cur'
        return(
            <div className="tab">
                <div className="tab-title">
                    <div className="title">
                        {
                            this.props.menu.map((item) => {
                                return <span
                                    key={item.dataindex}
                                    className={item.dataindex === this.state.selectMenu ? cur : ''}
                                    onClick={() => this.handleClickTab(item.dataindex)}
                                >{item.name}</span>
                            })
                        }
                    </div>
                </div>
                {
                    isArray(this.props.children) ? (
                        this.props.children.map(item => {
                            if(this.state.selectMenu === item.props.dataindex){
                                return item
                            }
                        })
                    ) : this.props.children
                }
            </div>
        )
    }
}

export default Tab